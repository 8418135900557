import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Bar from "../../../components/atoms/Bar";
import Tooltip from "../../../components/atoms/Tooltip";
import BarSkeleton from "../../../components/atoms/BarSkeleton";
import ExportFile from '../../../components/molecules/ExportFile';

import formatNumber from "../../../helpers/formatting/formatNumber";

const UsersActivityChart = ({ data, isLoading, exportChart, timePeriod }) => {

    const { t } = useTranslation('common');
    const [max, setMaxValue] = useState(10);

    useEffect(() => {
        if (!data) return;
        setMaxValue(Math.max(...[data.downloads, data.shares, data.previews]));
    }, [data]);

    return (
        <div className="bc-white p-4 border-radius h-100 exportable">
            <div className="d-flex align-items-center justify-content-between">
                {isLoading &&
                    <div className="placeholder-glow w-75">
                        <div className="placeholder c-grey-200 w-75 border-radius px-5"></div>
                    </div>
                }
                {!isLoading &&
                    <>
                        <div className="d-flex align-items-center">
                            <div className="fw-semi-bold c-black-100 text-truncate">{t("assets:users_activity")}</div>
                            <Tooltip
                                anchor={`tooltip-anchor-users-activity`}
                                description={t("assets:users_activity_tooltip")} />
                        </div>
                        {data && (data?.previews > 0 || data?.downloads > 0 || data?.shares > 0) &&
                            <div data-html2canvas-ignore>
                                <ExportFile
                                    exportFile={exportChart} />
                            </div>
                        }
                    </>
                }
            </div>
            <div className="d-none c-black-100 exportable-label">
                {timePeriod}
            </div>
            <div>
                {!isLoading && (data?.previews > 0 || data?.downloads > 0 || data?.shares > 0) &&
                    <div className="pt-lg-5">
                        <Bar title={t("previews")}
                            color={"#1A359F"}
                            value={data?.previews ? formatNumber(data.previews) : 0}
                            maxValue={max} />
                        <Bar title={t("downloads")}
                            color={"#1988D8"}
                            value={data?.downloads ? formatNumber(data.downloads) : 0}
                            maxValue={max} />
                        <Bar title={t("shares")}
                            color={"#69ADDE"}
                            value={data?.shares ? formatNumber(data.shares) : 0}
                            maxValue={max} />
                    </div>
                }
                {!isLoading && data?.previews === 0 && data?.downloads === 0 && data?.shares === 0 &&
                    <div className="h-100">
                        <div className="d-flex flex-column justify-content-center align-items-center pt-5">
                            <img
                                alt="not data"
                                src={`${process.env.PUBLIC_URL}/images/empty/users-activity.svg`} />
                            <div className="c-dark-blue fw-semi-bold">{t("assets:empty_users_activity_message")}</div>
                            <div className="text-center">
                                <span className="c-dark-blue">
                                    {t("assets:empty_activity_decryption")}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                {isLoading &&
                    <div className="pt-lg-5">
                        <BarSkeleton />
                        <BarSkeleton />
                        <BarSkeleton />
                    </div>
                }
            </div>
        </div>
    );
}

export default UsersActivityChart;