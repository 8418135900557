import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import Arrow from "../atoms/Arrow";
import { EN_LANGUAGE_KEY, FR_LANGUAGE_KEY } from "../../helpers/constants/constants";

const LanguageSelector = ({ selectedLanguage, changeLanguageHandler }) => {
    const [showLanguageToggle, setLanguageToggle] = useState(false);

    return (<>
        <Dropdown className="py-0 d-none d-lg-flex align-items-center mx-3">
            <Dropdown.Toggle className="border-0 btn-link bg-transparent text-decoration-none p-0 ">
                <span className="d-flex">
                    <img
                        alt="info-circle"
                        className="px-1"
                        src={`${process.env.PUBLIC_URL}/images/icons/global.svg`} />
                    <span style={{ minWidth: 20 }}
                        className="d-none d-xl-block text-highlight">
                        {selectedLanguage.toUpperCase()}
                    </span>
                </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="px-2 dropdown-menu-end" >
                <Dropdown.Item onClick={() => changeLanguageHandler(EN_LANGUAGE_KEY)}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className={selectedLanguage === EN_LANGUAGE_KEY && 'c-brand-dark-blue'}>
                            English
                        </div>
                        {selectedLanguage === EN_LANGUAGE_KEY &&
                            <img
                                className="img-selected"
                                alt="selected"
                                style={{ height: 16, width: 16 }}
                                src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                        }
                    </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguageHandler(FR_LANGUAGE_KEY)}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className={selectedLanguage === FR_LANGUAGE_KEY && 'c-brand-dark-blue'}>
                            Française
                        </div>
                        {selectedLanguage === FR_LANGUAGE_KEY &&
                            <img
                                className="img-selected"
                                alt="selected"
                                style={{ height: 16, width: 16 }}
                                src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                        }
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        <div className="mb-4 ms-md-4 px-4 d-lg-none">
            <div onClick={() => setLanguageToggle(!showLanguageToggle)}
                className={`d-flex cursor-pointer justify-content-between p-3 ms-md-2 ${showLanguageToggle && 'bc-grey-200 rounded-3'}`}>
                <span>
                    <img
                        alt="language"
                        className="pe-1"
                        src={`${process.env.PUBLIC_URL}/images/icons/global.svg`} />
                    <span className="ps-3">{selectedLanguage.toUpperCase()}</span>
                </span>
                <Arrow turnOn={showLanguageToggle} />
            </div>
            {showLanguageToggle &&
                <>
                    <div className={`mt-1 cursor-pointer p-3 mb-3 ms-md-2 ${selectedLanguage === EN_LANGUAGE_KEY && 'bc-grey-200 rounded-3'}`}>
                        <div onClick={() => changeLanguageHandler(EN_LANGUAGE_KEY)}
                            className="d-flex align-items-center justify-content-between">
                            <div>
                                English
                            </div>
                            {selectedLanguage === EN_LANGUAGE_KEY &&
                                <img
                                    alt="selected"
                                    style={{ height: 16, width: 16 }}
                                    src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                            }
                        </div>
                    </div>

                    <div className={`mt-1 cursor-pointer p-3 ms-md-2 ${selectedLanguage !== EN_LANGUAGE_KEY && 'bc-grey-200 rounded-3'}`}>
                        <div onClick={() => changeLanguageHandler(FR_LANGUAGE_KEY)}
                            className="d-flex align-items-center justify-content-between">
                            <div>
                                Française
                            </div>
                            {selectedLanguage === FR_LANGUAGE_KEY &&
                                <img
                                    alt="selected"
                                    style={{ height: 16, width: 16 }}
                                    src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    </>
    );
}

export default LanguageSelector;