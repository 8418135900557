const generateIntervals = (combinedArray) => {
    let min;
    let max;
    let count = 5;
    
    const { smallestInt, largestInt } = combinedArray.reduce(
        (acc, obj) => ({
            smallestInt: Math.min(acc.smallestInt, obj),
            largestInt: Math.max(acc.largestInt, obj),
        }),
        { smallestInt: Number.MAX_SAFE_INTEGER, largestInt: Number.MIN_SAFE_INTEGER }
    );

    if (smallestInt !== Number.MAX_SAFE_INTEGER && largestInt !== Number.MIN_SAFE_INTEGER){
        if (largestInt < 5) return [0, 1, 2, 3, 4];
        else {
            if ((largestInt - smallestInt) < 40)
            {
                min = smallestInt / 2;
                max = largestInt * 2;
            } else {
                min = smallestInt;
                max = largestInt;
            }
        }
    }

    let intervalSize = Math.ceil((max - min) / (count - 1));
    const result = [];
    if(intervalSize === 1)
        intervalSize = 2;
    for (let i = 0; i < count; i++)
        if (intervalSize > 100)
            result.push((Math.floor((min + i * intervalSize) / 100) * 100));
        else
            if (intervalSize > 20)
                result.push((Math.floor((min + i * intervalSize) / 10) * 10));
            else
                result.push(min + i * intervalSize);
    return result;
}

export default generateIntervals;