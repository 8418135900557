import { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from "react-i18next";

import Arrow from "../atoms/Arrow";
import { MAX_TABLET_HORIZONTAL_SCREEN_WIDTH } from "../../helpers/constants/constants";
import { AssetStateContext } from "../../contexts/AssetStateContextProvider";

const Settings = ({ minWidth }) => {
    const { t } = useTranslation(['common']);

    const [showInactiveAssets, setShowInactiveAssets] = useContext(AssetStateContext);
    const [show, setShow] = useState(false);

    return (
        <>
            <Dropdown className="d-none d-lg-flex p-2 ps-3">
                <Dropdown.Toggle className="border-0 btn-link bg-transparent text-decoration-none d-flex align-items-center p-0">
                    <span className="d-flex" onClick={() => setShow(true)}>
                        <img
                            alt="settings"
                            src={`${process.env.PUBLIC_URL}/images/icons/settings-2.svg`} />
                        <span style={{ minWidth: minWidth }}
                            className="ps-2 d-none d-xl-flex text-highlight">
                            {t('settings')}
                        </span>
                    </span>
                </Dropdown.Toggle>
                <Modal className="d-none d-lg-block"
                    show={show && window.innerWidth > MAX_TABLET_HORIZONTAL_SCREEN_WIDTH}
                    onHide={() => setShow(false)}>
                    <Dropdown.Menu className="border-radius settings p-4" style={{ width: 387 }}>
                        <h6 className="c-black-100 mb-3">{t("settings")}</h6>
                        <div className="form-check form-switch">
                            <input className="form-check-input"
                                checked={showInactiveAssets}
                                onChange={() => { setShowInactiveAssets(!showInactiveAssets) }}
                                type="checkbox"
                                role="switch" />
                            <span className="brand-dark-blue">{t('include_deleted')}</span>
                        </div>
                        <div className="text-sm c-dark-blue">
                            {t('include_deleted_description')}
                        </div>
                    </Dropdown.Menu>
                </Modal>
            </Dropdown >

            <div className="mb-4 ms-md-4 px-4 d-lg-none">
                <div onClick={() => setShow(!show)}
                    className={`d-flex cursor-pointer justify-content-between rounded-3 p-3 ms-md-2 ${show && 'bc-grey-200'}`}>
                    <span>
                        <img
                            alt="settings"
                            src={`${process.env.PUBLIC_URL}/images/icons/settings.svg`} />
                        <span className="ps-3">{t('settings')}</span>
                    </span>
                    <Arrow turnOn={show} />
                </div>
                {show &&
                    <div className="py-4 px-3">
                        <h6 className="c-black-100 mb-3">{t("settings")}</h6>
                        <div className="form-check form-switch">
                            <input className="form-check-input"
                                checked={showInactiveAssets}
                                onChange={() => { setShowInactiveAssets(!showInactiveAssets) }}
                                type="checkbox"
                                role="switch" />
                            <span className="brand-dark-blue">{t('include_deleted')}</span>
                        </div>
                        <div className="text-sm c-dark-blue">
                            {t('include_deleted_description')}
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default Settings;