
import { PNG } from "../constants/constants";
import html2canvas from 'html2canvas';

const exportAsImage = (ref, format, fileName, setShowError) => {
    html2canvas(ref.current,
        {
            scale: 3,
            removeContainer: true,
            backgroundColor: format === PNG ? null : 'white',
            onclone: (document) => {
                if (format === PNG) {
                    Array.from(
                        document.getElementsByClassName('exportable'),
                    ).forEach((e) => {
                        e.classList.remove('bc-white');
                    })
                }

                Array.from(
                    document.getElementsByClassName('exportable-label'),
                ).forEach((e) => {
                    e.classList.remove('d-none');
                })

                Array.from(
                    document.querySelectorAll("*"),
                ).forEach((e) => {
                    let existingStyle = e.getAttribute("style") || "";
                    e.setAttribute("style", existingStyle + "; font-family: Source Sans Pro, sans-serif !important");
                });
            }
        })
        .then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const link = document.createElement('a');
            link.download = fileName + format;
            link.href = imgData;
            link.click();
        })
        .catch(() => {
            setShowError(true);
        });
}

export default exportAsImage;