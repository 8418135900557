const BarSkeleton = () => {
    return (
        <div className="row d-flex align-items-center mb-3 mt-3 pt-1 g-0">
            <div className="col-10 col-lg-3 placeholder-glow col-xl-2 order-1">
                <div className="placeholder c-grey-200 border-radius w-75"></div>
            </div>
            <div className="col-12 col-lg-8 placeholder-glow col-xl-9 order-3 order-lg-2 pe-lg-3 ps-0">
                <div className="placeholder c-grey-200 border-radius  w-100"></div>
            </div>
            <div className="col-2 col-lg-1 placeholder-glow order-2 order-lg-3 text-end">
                <div className="placeholder c-grey-200 border-radius w-100"></div>
            </div>
        </div>
    );
}

export default BarSkeleton;