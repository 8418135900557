import { useTranslation } from "react-i18next";
import Footer from "../../core/Footer";

const NotFoundPage = () => {
    const { t } = useTranslation('errors');

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img
                    alt="page not found"
                    src={`${process.env.PUBLIC_URL}/images/errors/not-found.svg`} />
                <h1 className="c-dark-blue fw-semi-bold text-center mb-2">404 {t("page_not_found")}</h1>
                <div className="text-center w-75">
                    <span className="c-dark-blue">
                        {t("page_not_found_content")}
                    </span>
                </div>
            </div>
            <Footer />
        </>);
}

export default NotFoundPage;