import percentage from "./percentage";
import adjustPresent from "./adjustPresent";

const formatShareActivity = (data) => {
    const total = data.downloadFromShare + data.shareByEmail + data.share;
    return [
        {
            id: 1,
            label: 'downloads_from_share',
            realValue: data.downloadFromShare,
            value: adjustPresent(data.downloadFromShare, total),
            color: '#1988D8',
            present: percentage(data.downloadFromShare, total)
        }, {
            id: 2,
            label: 'shares_by_link',
            realValue: data.share,
            value: adjustPresent(data.share, total),
            color: '#D73A02',
            present: percentage(data.share, total)
        }, {
            id: 3,
            label: 'shares_by_email',
            realValue: data.shareByEmail,
            value: adjustPresent(data.shareByEmail, total),
            color: '#F9662D',
            present: percentage(data.shareByEmail, total)
        }
    ].sort((a, b) => b.realValue - a.realValue);
}

export default formatShareActivity;