import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Tooltip from "../../../components/atoms/Tooltip";
import EmptyPage from "../../../components/atoms/EmptyPage";
import LineChart from "../../../components/organisms/LineChart";
import ExportFile from "../../../components/molecules/ExportFile";
import LineChartSkeleton from "../../../components/atoms/LineChartSkeleton";

import selectDate from "../../../helpers/dates/selectDate";
import formatNumber from "../../../helpers/formatting/formatNumber";
import getYearTransitions from "../../../helpers/dates/getYearTransitions";
import generateIntervals from "../../../helpers/formatting/generateIntervals";
import getTooltipDateFormat from "../../../helpers/dates/getTooltipDateFormat";
import calculateDaysBetween from "../../../helpers/dates/calculateDaysBetween";

const AssetPerformanceChart = ({ data, isLoading, exportChart }) => {
    const { t } = useTranslation(['assets']);

    //Lines color codes    blue      orang      green
    const lineColors = ['#1335AF', '#D73A02', '#3F7A1A'];
    const [dataSet, setData] = useState([]);
    const [min, setMin] = useState();
    const [max, setMax] = useState();
    const [gridYValues, setGridYValues] = useState();
    const [interval, setInterval] = useState();
    const [yearTransitions, setYearTransitions] = useState([]);

    useEffect(() => {
        const dataList = [];

        if (!data || data.length === 0) {
            setData(dataList);
            return;
        }
        const calcYearTransitions = getYearTransitions(data);
        setYearTransitions(calcYearTransitions);
        const calcInterval = calculateDaysBetween(data[0]?.intervalStart, data[0]?.intervalEnd);
        setInterval(calcInterval);
        dataList.push({
            id: "total_downloads",
            color: lineColors[0],
            data: data.map((m, index) => ({
                "x": selectDate(m.intervalStart, m.intervalEnd, index === data?.length - 1),
                "y": m.downloads
            }))
        });
        dataList.push({
            id: "total_shares",
            color: lineColors[1],
            data: data.map((m, index) => ({
                "x": selectDate(m.intervalStart, m.intervalEnd, index === data?.length - 1),
                "y": m.shares
            }))
        });
        dataList.push({
            id: "total_previews",
            color: lineColors[2],
            data: data.map((m, index) => ({
                "x": selectDate(m.intervalStart, m.intervalEnd, index === data?.length - 1),
                "y": m.previews
            }))
        });
        setData(dataList);

        const combinedArray = [...data.map(v => v.downloads), ...data.map(v => v.previews), ...data.map(v => v.shares)];
        let generatedIntervals = generateIntervals(combinedArray);
        setGridYValues(generatedIntervals);
        setMin(generatedIntervals[0]);
        const arrayMax = Math.max(...combinedArray);
        setMax(arrayMax > generatedIntervals[4] ? arrayMax : generatedIntervals[4]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const tooltipRender = (slice) => {
        if (!slice) return;
        const points = slice.points;
        const point = data[slice.points[2].index];
        let dateRange;
        const calcRange = getTooltipDateFormat(point, interval, yearTransitions);

        if (calcRange?.hours)
            dateRange = calcRange?.hours;
        else if (calcRange.to)
            if (t('date:' + calcRange.from[0], { date: new Date(calcRange.from[1]) }) === t('date:' + calcRange.to[0], { date: new Date(calcRange.to[1]) }))
                dateRange = t('date:' + calcRange.from[0], { date: new Date(calcRange.from[1]) });
            else
                dateRange = t('date:' + calcRange.from[0], { date: new Date(calcRange.from[1]) }) + " - " + t('date:' + calcRange.to[0], { date: new Date(calcRange.to[1]) });
        else
            dateRange = t('date:' + calcRange.from[0], { date: new Date(calcRange.from[1]) });

        return (
            <div className="bc-white border-radius box-shadow chart-tooltip"
                style={{ minWidth: 250 }} >
                <div className="p-3">
                    <div className="c-grey-800 text-sm">{dateRange}</div>
                    {points.map(d => (
                        <div key={d.serieColor} className="d-flex justify-content-between">
                            <span>
                                <span className="dot" style={{ backgroundColor: d.serieColor }}></span>
                                <span className='ps-2'>{t(d.serieId)}</span>
                            </span>
                            <span>{formatNumber(d.data.y)}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (<>
        <div className="bc-white p-4 pt-3 border-radius exportable">
            {!isLoading &&
                <div className="row g-0">
                    <div className="col-12 col-lg-6 d-flex text-nowrap c-black-100 justify-content-between">
                        <div className="d-flex align-self-center">
                            <span className="fw-semi-bold">{t('asset_performance')}</span>
                            <Tooltip
                                anchor={'tooltip-anchor-uploads'}
                                description={t('asset_performance_tooltip')} />
                        </div>
                        {dataSet?.length > 0 &&
                            <div data-html2canvas-ignore className="d-lg-none">
                                <ExportFile
                                    exportFile={exportChart} />
                            </div>
                        }
                    </div>
                    {dataSet?.length > 0 &&
                        <div className="col-12 col-lg-6">
                            <div className="d-block d-lg-flex align-items-center justify-content-end">
                                <div className="d-block d-lg-flex justify-content-end align-items-center">
                                    {dataSet.map(d => (
                                        <div className="text-nowrap" key={d.color}>
                                            <span className="dot me-2" style={{ backgroundColor: d.color }}></span>
                                            <span className="c-black-100 fw-light text-nowrap pe-3">{t(d.id)}</span>
                                        </div>
                                    ))}
                                </div>
                                <div data-html2canvas-ignore
                                    className="d-none d-lg-block">
                                    <ExportFile
                                        exportFile={exportChart} />
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-12 d-none c-black-100 exportable-label">
                        {t('date:day_month_year', { date: new Date(data?.[0]?.intervalStart) })}
                        <span> - </span>
                        {t('date:day_month_year', { date: new Date(data?.[data?.length - 1]?.intervalStart) })}
                    </div>
                </div>
            }
            {!isLoading && dataSet?.length > 0 &&
                < LineChart
                    data={dataSet.toReversed()}
                    min={min}
                    max={max}
                    gridYValues={gridYValues}
                    tooltipRender={tooltipRender}
                    height={330}
                    interval={interval}
                    yearTransitions={yearTransitions.map(y => t('date:day_month_year', { date: new Date(y) }))}
                />
            }
            {!isLoading && dataSet?.length === 0 &&
                < EmptyPage
                    header={t("dam:no_uploads")}
                    message={t("dam:no_data_message")}
                    img={`${process.env.PUBLIC_URL}/images/empty/assets-upload.svg`}
                />
            }
            {isLoading &&
                < LineChartSkeleton />
            }
        </div>
    </>
    );
}

export default AssetPerformanceChart;