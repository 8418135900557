import { createContext, useState } from 'react';

export const AssetStateContext = createContext();

export const AssetStateContextProvider = props => {
    const [showInactiveAssets, setShowInactiveAssets] = useState(false);

    return (
        <AssetStateContext.Provider value={[showInactiveAssets, setShowInactiveAssets]}>
            {props.children}
        </AssetStateContext.Provider>
    );
};