import { useTranslation } from "react-i18next";

import Footer from "../../core/Footer";

const ServerErrorPage = () => {
    const { t } = useTranslation('errors');

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img
                    alt="server error occurred"
                    src={`${process.env.PUBLIC_URL}/images/errors/internal-error.svg`} />
                <h1 className="c-dark-blue fw-semi-bold text-center mb-2">{t("something_went_wrong")}</h1>
                <div className="text-center w-75">
                    <span className="c-dark-blue">
                        {t("server_error_content")}
                    </span>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ServerErrorPage;