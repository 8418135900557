import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom"

import AssetLevelReporting from "./components/AssetLevelReporting";
import { FROM, TO, OPTION, ID } from "../../helpers/constants/constants";

const AssetReportingLayoutPage = () => {
    const [queryParameters] = useSearchParams();

    const [queryParams, setQueryParams] = useState();

    useEffect(() => {
        setQueryParams({
            assetId: queryParameters.get(ID),
            from: queryParameters.get(FROM),
            to: queryParameters.get(TO),
            option: queryParameters.get(OPTION)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="py-2">
            {queryParams &&
                <AssetLevelReporting queryParams={queryParams} />
            }
        </div>);
}

export default AssetReportingLayoutPage;