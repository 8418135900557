const BarChartSkeleton = () => {
    return (<>
        <div className="placeholder-glow">
            <div className="placeholder c-grey-200 border-radius w-25"></div>
        </div>
        <div className="row overflow-hidden bar-chart-height mt-lg-4 w-100">
            <div className="col-12">
                <div className="row justify-content-between">
                    <div className="col-6 col-md-4 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-100"></div>
                    </div>
                    <div className="col-2 col-md-1 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-50"></div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="row justify-content-between">
                    <div className="col-4 col-md-3 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-100"></div>
                    </div>
                    <div className="col-2 col-md-1 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-50"></div>
                    </div>
                </div>
            </div>


            <div className="col-12">
                <div className="row justify-content-between">
                    <div className="col-6 col-md-4 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-100"></div>
                    </div>
                    <div className="col-2 col-md-1 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-50"></div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="row justify-content-between">
                    <div className="col-4 col-md-3 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-100"></div>
                    </div>
                    <div className="col-2 col-md-1 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-50"></div>
                    </div>
                </div>
            </div>


            <div className="col-12">
                <div className="row justify-content-between">
                    <div className="col-6 col-md-4 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-100"></div>
                    </div>
                    <div className="col-2 col-md-1 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-50"></div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="row justify-content-between">
                    <div className="col-4 col-md-3 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-100"></div>
                    </div>
                    <div className="col-2 col-md-1 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-50"></div>
                    </div>
                </div>
            </div>


            <div className="col-12">
                <div className="row justify-content-between">
                    <div className="col-6 col-md-4 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-100"></div>
                    </div>
                    <div className="col-2 col-md-1 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-50"></div>
                    </div>
                </div>
            </div>

            <div className="col-12">
                <div className="row justify-content-between">
                    <div className="col-4 col-md-3 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-100"></div>
                    </div>
                    <div className="col-2 col-md-1 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-50"></div>
                    </div>
                </div>
            </div>


            <div className="col-12">
                <div className="row justify-content-between">
                    <div className="col-6 col-md-4 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-100"></div>
                    </div>
                    <div className="col-2 col-md-1 placeholder-glow">
                        <div className="placeholder c-grey-200 border-radius w-50"></div>
                    </div>
                </div>
            </div>

        </div>
    </>);
}

export default BarChartSkeleton;