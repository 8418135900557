const EmptyPage = ({ header, message, img }) => {
    return (<div className="h-100 d-flex flex-column justify-content-center align-items-center">
        <img
            className="mt-5"
            alt="page not found"
            src={img} />
        <div className="c-dark-blue fw-semi-bold">{header}</div>
        <div className="text-center w-75 pb-5 mb-1">
            <span className="c-dark-blue">
                {message}
            </span>
        </div>
    </div>);
}

export default EmptyPage;