import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";

import Tooltip from "../atoms/Tooltip";
import EmptyPage from "../atoms/EmptyPage";
import BarChartSkeleton from "../atoms/BarChartSkeleton";

import formatNumber from "../../helpers/formatting/formatNumber";
import calculatePercentage from "../../helpers/formatting/calculatePercentage";
import getAssetRedirectUrl from "../../helpers/formatting/getAssetRedirectUrl";
import { ASCENDING_SORT_ORDER, DESCENDING_SORT_ORDER } from "../../helpers/constants/constants";

const BarChart = ({
    data,
    color,
    headerText,
    tooltipText,
    emptyHeader,
    emptyMessage,
    emptyImg,
    selector,
    isLoading,
    sortOrder,
    setSortOrder,
    assetTypes,
    selectedAssetType,
    setAssetType,
    from,
    to,
    selectedOption
}) => {

    const { t } = useTranslation('common');

    const [max, setMaxValue] = useState(10);

    useEffect(() => {
        if (!Array.isArray(data) || data?.length === 0) return;
        setMaxValue(Math.max(...data.map(a => a.count)));
        handleOptionChange(sortOrder);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleOptionChange = (order) => {
        if (!order || order === sortOrder) return;
        setSortOrder(order);
    };

    const renderDropdown = () => {
        return (
            <> {data && data?.length > 0 && <>
                <div className="c-black-100 pb-2 pb-md-0 margin-top-1 pe-3 c-grey-800">{t('filter_by')}</div>
                <div className="table-header cursor-pointer no-selection">
                    <Dropdown>
                        <Dropdown.Toggle className="d-flex align-items-center">
                            <img
                                className="pe-2"
                                style={{ height: 20 }}
                                alt={`info-${selectedAssetType?.selector?.toLowerCase()}`}
                                src={`${process.env.PUBLIC_URL}/images/icons/assets/${selectedAssetType?.selector?.toLowerCase()}.svg`} />
                            <span className="fw-normal"> {t(`assets:${selectedAssetType?.name}`)}</span>
                            <img
                                alt="Arrow down"
                                src={`${process.env.PUBLIC_URL}/images/icons/arrow-down.svg`} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="asset-dropdown">
                            {assetTypes && assetTypes?.map((type) => (
                                <Dropdown.Item
                                    key={type.id}
                                    className="d-flex justify-content-between"
                                    onClick={() => setAssetType(type)}>
                                    <span>
                                        <img
                                            className="pe-2"
                                            style={{ height: 20 }}
                                            alt={`info-${type?.selector?.toLowerCase()}`}
                                            src={`${process.env.PUBLIC_URL}/images/icons/assets/${type?.selector}.svg`}
                                        />
                                        {t(`assets:${type?.name?.toLowerCase()}`)}
                                    </span>
                                    <span>
                                        {selectedAssetType.id === type.id &&
                                            <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                        }
                                    </span>
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>}
            </>
        )
    }

    return (
        <div className="bc-white py-4 ps-4 pe-3 border-radius mt-4">
            {!isLoading && <>
                <div className="row">
                    <div className="col-12 col-md-4 d-flex align-items-center">
                        <span className="fw-semi-bold c-black-100 text-truncate">{headerText}</span>
                        {tooltipText &&
                            <Tooltip
                                anchor={`tooltip-anchor-${selector}`}
                                description={tooltipText} />
                        }
                    </div>
                    <div className="col-12 col-lg-8 d-block d-md-flex justify-content-lg-end text-start mt-3 my-md-3 my-lg-0 ">
                        {data && data?.length !== 0 && <>
                            <div className="c-black-100 pb-2 pb-md-0 margin-top-1 c-grey-800">{t('sort_by')}</div>
                            <div className="d-flex flex-md-row ps-md-2 pe-md-4 pb-3 pb-md-0 me-2">
                                <div className="d-flex align-items-center">
                                    <input
                                        id={`radio-1-${selector}`}
                                        type="radio"
                                        value={DESCENDING_SORT_ORDER}
                                        checked={sortOrder === DESCENDING_SORT_ORDER}
                                        onChange={() => handleOptionChange(DESCENDING_SORT_ORDER)}
                                    />
                                    <label htmlFor={`radio-1-${selector}`} className="ps-1 pe-md-2 c-dark-blue">
                                        {t('most_to_least')}
                                    </label>
                                </div>
                                <div className="d-flex align-items-center ps-2">
                                    <input
                                        id={`radio-2-${selector}`}
                                        type="radio"
                                        value={ASCENDING_SORT_ORDER}
                                        checked={sortOrder === ASCENDING_SORT_ORDER}
                                        onChange={() => handleOptionChange(ASCENDING_SORT_ORDER)}
                                    />
                                    <label htmlFor={`radio-2-${selector}`} className="ps-1 c-dark-blue">
                                        {t('least_to_most')}
                                    </label>
                                </div>
                            </div>
                        </>}
                        <div className="d-none d-lg-flex align-items-center">
                            {renderDropdown()}
                        </div>
                    </div>
                    <div className="col-12 d-lg-none d-flex justify-content-between pb-3">
                        {renderDropdown()}
                    </div>
                </div>
                {data && data?.length !== 0 &&
                    <div style={{ position: 'relative' }}>
                        <div className="mt-lg-4 bar-chart-height"
                            style={{ overflowY: "auto", overflowX: "hidden" }}>
                            {data?.map((item, index) => (
                                <div key={index + selector} className={`row mb-3 pe-2 }`}>
                                    <div className="col-10 ellipsis mb-2">
                                        <img
                                            className={`pe-2 ${item.isDeleted && 'filter-gray'}`}
                                            style={{ height: 20 }}
                                            alt={`info-${item?.assetType?.toLowerCase()}`}
                                            src={`${process.env.PUBLIC_URL}/images/icons/assets/${item?.assetType?.toLowerCase()}.svg`} />
                                        <a href={getAssetRedirectUrl(item.id, from, to, selectedOption?.value)}
                                            rel="noreferrer"
                                            target="_blank"
                                            className={`${item.isDeleted ? 'c-grey-800 disabled-link' : 'c-dark-blue'}`}>
                                            {item.name}
                                        </a>
                                    </div>
                                    <div className="col-2 text-end">
                                        <span className={`pe-2 ${item.isDeleted ? 'c-grey-800' : 'c-dark-blue'}`}>{formatNumber(item.count)}</span>
                                    </div>
                                    <div className="col-12 pe-3">
                                        <div style={{ backgroundColor: item.isDeleted ? '#D2D2D2' : color, width: calculatePercentage(item.count, max) }}
                                            className="bar rounded-5"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {(!data || data?.length === 0) &&
                    <div className="bar-chart-height mt-lg-4">
                        <EmptyPage
                            header={emptyHeader}
                            message={emptyMessage}
                            img={emptyImg} />
                    </div>
                }
            </>
            }
            {isLoading &&
                <BarChartSkeleton />
            }
        </div >
    );
}

export default BarChart;