import { useState } from 'react';
import { useTranslation } from "react-i18next";
import TermsModal from '../components/organisms/TermsModal';

const Footer = () => {
    const { t, i18n } = useTranslation('footer');

    const [modalOpen, setModalOpen] = useState(false);

    return (
        <footer className="bc-grey-200">
            <div className="d-flex flex-md-row flex-column justify-content-between py-3">
                <div>
                    <a className="text-sm c-dark-blue pe-3 fw-light" href={`https://www.datacm.com/${i18n.language + '/'}privacy-policy/`} target="_blank" rel="noreferrer">
                        {t("security")} / {t("privacy_policy")}
                    </a>
                    <span className="d-block d-md-inline-block text-sm mb-1 mb-md-0 mt-2 mb-md-0 px-0 px-md-3 c-dark-blue fw-light link-span" onClick={() => setModalOpen(true)}>
                        {t("terms_of_use")} / {t("copyright")}
                    </span>
                </div>
                <TermsModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
                <div>
                    <a className="text-sm c-dark-blue fw-light" href="https://www.datacm.com/" target="_blank" rel="noreferrer">
                        ©2020-{new Date().getFullYear()} - DCM {t("all_rights_reserved")}
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;