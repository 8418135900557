const dateTo12Hour = (value) => {
    let date = new Date(value);
    date.setUTCMinutes(date.getUTCMinutes() + 1);
    let hours = date.getHours();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ' ' + ampm;
}

export default dateTo12Hour;