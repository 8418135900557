import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Dropdown from "react-bootstrap/Dropdown";

import formatNumber from "../../helpers/formatting/formatNumber";
import getAssetRedirectUrl from "../../helpers/formatting/getAssetRedirectUrl";
import { ASSETS_TABLE_CAPACITY, ASCENDING_SORT_ORDER, DESCENDING_SORT_ORDER } from "../../helpers/constants/constants";

const AssetsTable = ({ data, isLoading, from, to, selectedOption }) => {
    const { t } = useTranslation(['common', 'assets']);

    const [sortedData, setSortedData] = useState(data);
    const [sortProperty, setSortProperty] = useState("total");
    const [sortOrder, setSortOrder] = useState(DESCENDING_SORT_ORDER);

    const changeSortOrder = (order, property) => {
        const sortArray = type => {
            let sorted = order === DESCENDING_SORT_ORDER ? [...data].sort((a, b) => b[type] - a[type]) : [...data].sort((a, b) => a[type] - b[type]);
            const length = sorted?.length;
            if (ASSETS_TABLE_CAPACITY - length > 0) {
                sorted = sorted.concat(emptyAssets.slice(0, ASSETS_TABLE_CAPACITY - length));
            }

            setSortedData(sorted);
        };

        setSortOrder(order);
        setSortProperty(property);

        if (data?.length) {
            sortArray(property);
        }
    }

    useEffect(() => {
        if (data?.length)
            changeSortOrder(sortOrder, sortProperty)
        else
            setSortedData([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const AssetRow = ({ index, style }) => (
        <div
            style={style}
            className={`row border-top-grey-200 ps-3 ${sortedData[index]?.isDeleted ? 'c-grey-800' : 'c-dark-blue'}`}>
            <div className="col-md-3 col-lg-5 col-xl-6 d-flex align-items-center">
                {
                    sortedData[index]?.type &&
                    <img
                        className={`pe-2 ${sortedData[index]?.isDeleted && 'filter-gray'}`}
                        style={{ height: 20 }}
                        alt={`info-${sortedData[index]?.type?.toLowerCase()}`}
                        src={`${process.env.PUBLIC_URL}/images/icons/assets/${sortedData[index]?.type?.toLowerCase()}.svg`} />
                }

                {
                    sortedData[index]?.name && !sortedData[index]?.isDeleted &&
                    <a href={getAssetRedirectUrl(sortedData[index]?.id, from, to, selectedOption?.value)}
                        rel="noreferrer"
                        target="_blank"
                        className="c-dark-blue d-block text-truncate">
                        <span>{sortedData[index]?.name}</span>
                    </a>
                }

                {
                    sortedData[index]?.name && sortedData[index]?.isDeleted &&
                    <span className="d-block text-truncate user-select-none">{sortedData[index]?.name}</span>
                }
            </div>
            <div className="col-md-9 col-lg-7 col-xl-6">
                <div className="row h-100">
                    <div className="col-3 d-flex align-items-center">
                        {formatNumber(sortedData[index]?.total)}
                    </div>
                    <div className="col-3 d-flex align-items-center">
                        {formatNumber(sortedData[index]?.downloads)}
                    </div>
                    <div className="col-3 d-flex align-items-center">
                        {formatNumber(sortedData[index]?.shares)}
                    </div>
                    <div className="col-3 d-flex align-items-center">
                        {formatNumber(sortedData[index]?.previews)}
                    </div>
                </div>
            </div>
        </div>
    )

    const SortIcon = ({ property }) =>
    (
        <>
            {property === sortProperty
                ? <img
                    alt="arrow-sort-desc"
                    style={{ height: 20 }}
                    className={`px-1 ${sortOrder === DESCENDING_SORT_ORDER ? 'rotate-180' : ''}`}
                    src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                : <img
                    alt="arrow-sort"
                    style={{ height: 18 }}
                    className="px-1"
                    src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort.svg`} />
            }
        </>
    );

    const ColumnHeader = ({ property }) =>
    (
        <div
            className={`col-3 table-header cursor-pointer no-selection ${sortProperty === property ? 'fw-bold c-brand-dark-blue' : ''}`}>
            <Dropdown>
                <Dropdown.Toggle className="d-flex align-items-center">
                    <span className={`${property === sortProperty ? 'current' : ''}`}>
                        {t(property)}
                        <SortIcon property={property} />
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() => changeSortOrder(DESCENDING_SORT_ORDER, property)}
                        className={`${(sortOrder === DESCENDING_SORT_ORDER && sortProperty === property) ? 'active' : ''}`}>
                        <img
                            alt="arrow-sort-desc"
                            className={`px-2 rotate-180`}
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                        {t('most_to_least')}
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => changeSortOrder(ASCENDING_SORT_ORDER, property)}
                        className={`${(sortOrder === ASCENDING_SORT_ORDER && sortProperty === property) ? 'active' : ''}`}>
                        <img
                            alt="arrow-sort-asc"
                            className={`px-2`}
                            src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                        {t('least_to_most')}
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );

    const PlaceholderRow = ({ width }) =>
    (
        <div className="d-flex border-top-grey-200 text-sm h-100">
            <div className="placeholder-glow col-md-3 col-lg-5 col-xl-6 d-flex align-items-center">
                <span className={`placeholder c-grey-200 border-radius w-${width}`}>&nbsp;</span>
            </div>
            <div className="col-md-9 col-lg-7 col-xl-6">
                <div className="d-flex h-100">
                    <div className="placeholder-glow col-3 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${width}`}>&nbsp;</span>
                    </div>
                    <div className="placeholder-glow col-3 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${width}`}>&nbsp;</span>
                    </div>
                    <div className="placeholder-glow col-3 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${width}`}>&nbsp;</span>
                    </div>
                    <div className="placeholder-glow col-3 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${width}`}>&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
    );

    const EmptyRow = () =>
    (
        <div className="row border-top-grey-200 text-sm h-100">
            <div className="col-md-3 col-lg-5 col-xl-6 d-flex align-items-center"></div>
            <div className="col-md-9 col-lg-7 col-xl-6">
                <div className="row h-100">
                    <div className="col-3 d-flex align-items-center"></div>
                    <div className="col-3 d-flex align-items-center"></div>
                    <div className="col-3 d-flex align-items-center"></div>
                    <div className="col-3 d-flex align-items-center"></div>
                </div>
            </div>
        </div>
    );

    const emptyAsset = {
        downloads: null,
        id: null,
        name: null,
        previews: null,
        shares: null,
        total: null
    };

    const emptyAssets = [emptyAsset, emptyAsset, emptyAsset, emptyAsset, emptyAsset, emptyAsset, emptyAsset];

    return (
        <>
            {
                !isLoading &&
                <>
                    <div className="mt-3">
                        <div className="d-flex ps-3 py-2 bc-grey-200 border-radius" style={{ paddingRight: 10 }}>
                            <div className="col-md-3 col-lg-5 col-xl-6 grey-800">{t("asset_name")}</div>
                            <div className="col-md-9 col-lg-7 col-xl-6">
                                <div className="d-flex">
                                    <ColumnHeader property="total" />
                                    <ColumnHeader property="downloads" />
                                    <ColumnHeader property="shares" />
                                    <ColumnHeader property="previews" />
                                </div>
                            </div>
                        </div>
                        {sortedData?.length > 0 &&
                            <div style={{ height: '325px', position: "relative" }}>
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            className="c-grey-800"
                                            height={height}
                                            itemCount={sortedData?.length}
                                            itemSize={46}
                                            width={width}>
                                            {AssetRow}
                                        </List>
                                    )}
                                </AutoSizer>
                            </div>
                        }

                        {sortedData?.length === 0 &&
                            <div
                                className="d-flex flex-column justify-content-between px-3 position-relative"
                                style={{ height: '325px' }}>
                                {
                                    emptyAssets.map((_, index) => (
                                        <div key={index + 'empty-state'}>
                                            <EmptyRow />
                                        </div>
                                    ))
                                }
                                <div
                                    className="d-flex flex-column align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
                                    <img
                                        alt="page not found"
                                        src={`${process.env.PUBLIC_URL}/images/no-documents.svg`} />
                                    <div className="c-dark-blue fw-semi-bold">{t('assets:no_assets')}</div>
                                    <div className="text-center w-75">
                                        <span className="c-dark-blue">
                                            Select a different time range to view assets here
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </>
            }
            {isLoading && <>
                <div className="mt-3">
                    <div className="d-flex ps-3 pb-3" style={{ paddingRight: 10 }}>
                        <div className="placeholder-glow col-md-3 col-lg-5 col-xl-6">
                            <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                        </div>
                        <div className="col-md-9 col-lg-7 col-xl-6">
                            <div className="d-flex">
                                <div className="placeholder-glow col-3">
                                    <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                </div>
                                <div className="placeholder-glow col-3">
                                    <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                </div>
                                <div className="placeholder-glow col-3">
                                    <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                </div>
                                <div className="placeholder-glow col-3">
                                    <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-between ps-3 pb-3" style={{ height: 325, paddingRight: 10 }}>
                        <PlaceholderRow width={50} />
                        <PlaceholderRow width={75} />
                        <PlaceholderRow width={50} />
                        <PlaceholderRow width={75} />
                        <PlaceholderRow width={50} />
                        <PlaceholderRow width={75} />
                        <PlaceholderRow width={50} />
                    </div>
                </div>
            </>}
        </>
    );
}

export default AssetsTable;