const LineChartSkeleton = () => {
    return (
        <div style={{ height: 370 }} className="overflow-hidden">
            <div className="placeholder-glow mb-4">
                <span className="placeholder c-grey-200 border-radius px-5 mb-2"></span>
            </div>
            <div className="row">
                <div className="col-12 placeholder-glow d-flex mb-4">
                    <div className="placeholder c-grey-200 border-radius px-3 mb-2"></div>
                    <div className="w-100 line mx-2"></div>
                </div>
                <div className="col-12 placeholder-glow d-flex mb-4">
                    <div className="placeholder c-grey-200 border-radius px-3 mb-2"></div>
                    <div className="w-100 line mx-2"></div>
                </div>
                <div className="col-12 placeholder-glow d-flex mb-4">
                    <div className="placeholder c-grey-200 border-radius px-3 mb-2"></div>
                    <div className="w-100 line mx-2"></div>
                </div>
                <div className="col-12 placeholder-glow d-flex mb-4">
                    <div className="placeholder c-grey-200 border-radius px-3 mb-2"></div>
                    <div className="w-100 line mx-2"></div>
                </div>
                <div className="col-12 placeholder-glow d-flex mb-4">
                    <div className="placeholder c-grey-200 border-radius px-3 mb-2"></div>
                    <div className="w-100 line mx-2"></div>
                </div>
                <div className="col-12 placeholder-glow d-flex">
                    <div className="placeholder c-grey-200 border-radius px-3 mb-2"></div>
                    <div className="w-100 line mx-2"></div>
                </div>
                <div className="col placeholder-glow text-center">
                    <span className="placeholder c-grey-200 border-radius w-25"></span>
                </div>
                <div className="col placeholder-glow text-center">
                    <span className="placeholder c-grey-200 border-radius px-4"></span>
                </div>
                <div className="col placeholder-glow text-center">
                    <span className="placeholder c-grey-200 border-radius px-4"></span>
                </div>
                <div className="col placeholder-glow text-center">
                    <span className="placeholder c-grey-200 border-radius px-4"></span>
                </div>
                <div className="col placeholder-glow text-center">
                    <span className="placeholder c-grey-200 border-radius px-4"></span>
                </div>
                <div className="col placeholder-glow text-center">
                    <span className="placeholder c-grey-200 border-radius px-4"></span>
                </div>
                <div className="col placeholder-glow text-center">
                    <span className="placeholder c-grey-200 border-radius px-4"></span>
                </div>
                <div className="col placeholder-glow text-center">
                    <span className="placeholder c-grey-200 border-radius px-4"></span>
                </div>
                <div className="col placeholder-glow text-center">
                    <span className="placeholder c-grey-200 border-radius px-4"></span>
                </div>
            </div>
        </div>
    );
}

export default LineChartSkeleton;