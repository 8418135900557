import axios from "axios";
import customSerializer from "../helpers/formatting/customSerializer";

function EventsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getStartDate = (tenantId) => axios
        .get('/events/start-date', {
            params: {
                tenantId: tenantId
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getCountByAssetTypes = (tenantId, from, to, includeDeleted = false) => axios
        .get('/events/asset-types', {
            params: {
                tenantId: tenantId,
                from: from,
                to: to,
                includeDeleted: includeDeleted
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getUploads = (tenantId, from, to, includeDeleted = false) => axios
        .get('/events/uploads', {
            params: {
                tenantId: tenantId,
                from: from,
                to: to,
                includeDeleted: includeDeleted
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getAssetPerformance = (assetId, from, to, includeDeleted = false) => axios
        .get('/events/asset-performance', {
            params: {
                assetId: assetId,
                from: from,
                to: to,
                includeDeleted: includeDeleted
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getUniqueAssetPerformance = (assetId, from, to, includeDeleted = false) => axios
        .get('/events/unique-asset-performance', {
            params: {
                assetId: assetId,
                from: from,
                to: to,
                includeDeleted: includeDeleted
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    return {
        init,
        dispose,
        getStartDate,
        getCountByAssetTypes,
        getUploads,
        getAssetPerformance,
        getUniqueAssetPerformance
    };
}

export default EventsService;