const Trend = ({ value }) => {

    const getTrendSinge = (value) => {
        if (value > 0) return "+";
        return ""
    }
    
    return (<>
        {(!!value) && <div
            className={`${value >= 0 ? 'c-green' : 'c-red'} text-sm d-flex`}>
            {getTrendSinge(value)}{value.toFixed(2)}%
            <img alt={value >= 0 ? "Trend up" : "Trend down"}
                src={value >= 0 ? `${process.env.PUBLIC_URL}/images/icons/arrow-rise.svg`: `${process.env.PUBLIC_URL}/images/icons/arrow-fall.svg`} />
        </div>
        }
    </>
    );
}

export default Trend;