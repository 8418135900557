import { useTranslation } from "react-i18next";

import Footer from "../../core/Footer";

const LogoutPage = () => {
    const { t } = useTranslation('errors');

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img
                    alt="logged-out"
                    src={`${process.env.PUBLIC_URL}/images/errors/logged-out.svg`} />
                <h1 className="c-dark-blue fw-semi-bold text-center mb-2">{t("logged_out")}</h1>
            </div>
            <Footer />
        </>);
}

export default LogoutPage;