import { Tooltip as ReactTooltip } from "react-tooltip";
import { MAX_MOBILE_SCREEN_WIDTH } from "../../helpers/constants/constants";

const Tooltip = ({ description, anchor, place = 'top' }) => {

    const getTooltipLocation = () => {
        return window.innerWidth < MAX_MOBILE_SCREEN_WIDTH ? place : 'right';
    }

    return (
        <>
            <img
                data-html2canvas-ignore
                alt="info-circle"
                className={`px-1 ${anchor}`}
                src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
            <ReactTooltip
                style={{ maxWidth: 350 }}
                className="text-wrap"
                anchorSelect={`.${anchor}`}
                place={getTooltipLocation()}>
                {description}
            </ReactTooltip>
        </>
    );
}

export default Tooltip;