import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";

import Tooltip from "../../../components/atoms/Tooltip";
import PieChart from "../../../components/organisms/PieChart";
import ExportFile from '../../../components/molecules/ExportFile';

import getAssetTypeColor from "../../../helpers/colors/getAssetTypeColor";
import adjustPresent from "../../../helpers/formatting/adjustPresent";
import percentage from "../../../helpers/formatting/percentage";
import sum from "../../../helpers/arrays/sum";

const ActivityByAssetTypes = ({ activities, isLoading, from, to, exportChart }) => {

    const { t } = useTranslation(['common', 'assets', 'dam', 'date']);
    const [assetTypes, setAssetTypes] = useState([]);

    const [filterProperty, setFilterProperty] = useState("downloads");

    useEffect(() => {
        setAssetTypes([]);

        if (activities) {
            let allTypes = [];

            if (activities?.downloads?.length)
                allTypes.push(...activities?.downloads?.map(entry => entry.type));
            if (activities?.uploads?.length)
                allTypes.push(...activities?.uploads?.map(entry => entry.type));
            if (activities?.shares?.length)
                allTypes.push(...activities?.shares?.map(entry => entry.type));
            if (activities?.previews?.length)
                allTypes.push(...activities?.previews?.map(entry => entry.type));

            if (allTypes.length) {
                const uniqueTypes = new Set(allTypes);
                setAssetTypes(Array.from(uniqueTypes));
            }
        }
    }, [activities])

    const mapData = (assets) => {
        if (!assets) return;

        const totalCount = sum(assets, 'count');
        return assets.map((asset => (
            {
                id: asset.type,
                label: t(`assets:${asset.type.toLowerCase()}`),
                value: adjustPresent(asset.count, totalCount),
                realValue: asset.count,
                color: getAssetTypeColor(asset.type),
                percentage: percentage(asset.count, totalCount)
            })))
    }

    const getDataByType = (filter) => {
        switch (filter) {
            case 'downloads': return mapData(activities?.downloads);
            case 'shares': return mapData(activities?.shares);
            case 'previews': return mapData(activities?.previews);
            case 'uploads': return mapData(activities?.uploads);
            default: return mapData(activities?.downloads);
        }
    }

    return (
        <div className="bc-white p-4 pt-3 border-radius exportable">
            {!isLoading && <>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <span className="fw-semi-bold c-black-100 text-truncate">{t('dam:assets_activity_by_type')}</span>
                        <Tooltip
                            anchor={`tooltip-anchor-assets-activity-by-type`}
                            description={t('dam:assets_activity_by_type_description')} />
                    </div>
                    {activities &&
                        <div data-html2canvas-ignore>
                            <ExportFile
                                exportFile={exportChart} />
                        </div>
                    }
                </div>
                <div className="col-12 c-black-100 d-none exportable-label">
                    {t('date:day_month_year', { date: new Date(from) })}
                    <span> - </span>
                    {t('date:day_month_year', { date: new Date(to) })}
                </div>
            </>
            }
            {isLoading &&
                <div className="d-flex align-items-center placeholder-glow my-2" style={{ height: 24 }}>
                    <span className="placeholder c-grey-200 border-radius w-25">&nbsp</span>
                </div>
            }

            {
                <div className="d-none d-md-flex flex-row flex-wrap justify-content-center py-4">
                    {assetTypes?.length && !isLoading
                        ? <>
                            {assetTypes?.map((item, index) => (
                                <div key={item + index}>
                                    {
                                        <div className="d-flex align-items-center justify-content-between pe-5">
                                            <div className="d-flex align-items-center">
                                                <img
                                                    alt={`info-${item?.toLowerCase()}`}
                                                    className={`px-1`}
                                                    src={`${process.env.PUBLIC_URL}/images/assets/${item?.toLowerCase()}.svg`} />
                                                <span className="c-black-100 px-1">{t('assets:' + item?.toLowerCase())}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </>
                        :
                        <div className="d-flex align-items-center placeholder-glow py-2" style={{ height: 34 }}>
                            <span className="placeholder c-grey-200 border-radius w-25"></span>
                        </div>
                    }
                </div>
            }
            {!isLoading
                ? <div className="d-flex d-md-none row py-4">
                    {assetTypes?.length
                        ? <>
                            {
                                assetTypes?.map((item, index) => (
                                    <div className="col-6 py-1" key={item + index}>
                                        <div className="d-flex align-items-center">
                                            <img
                                                alt={`info-${item?.toLowerCase()}`}
                                                className={`px-1`}
                                                src={`${process.env.PUBLIC_URL}/images/assets/${item?.toLowerCase()}.svg`} />
                                            <span className="c-black-100 px-1">{t('assets:' + item?.toLowerCase())}</span>
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                        :
                        <div className="d-flex align-items-center" style={{ height: 34 }}>
                            <span className="c-grey-200 border-radius w-25"></span>
                        </div>
                    }
                </div>
                : <div className="d-block d-md-none py-4" style={{ marginTop: 17, marginBottom: 17 }}></div>
            }

            <div className="d-none d-md-block px-4">
                <div className="row">
                    <div className="py-3 col-md-6 col-lg-3 text-center placeholder-glow">
                        {isLoading
                            ? <span className="placeholder fw-semi-bold c-grey-200 border-radius w-50" style={{ height: 20 }}>{t('common:downloads')}</span>
                            : <span className="fw-semi-bold c-black-100 text-truncate">{t('common:downloads')}</span>}
                        <PieChart
                            data={mapData(activities?.downloads)}
                            emptyStatePlaceholder="No data"
                            isLoading={isLoading} />
                    </div>
                    <div className="py-3 col-md-6 col-lg-3 text-center placeholder-glow">
                        {isLoading
                            ? <span className="placeholder fw-semi-bold c-grey-200 border-radius w-50" style={{ height: 20 }}>{t('common:shares')}</span>
                            : <span className="fw-semi-bold c-black-100 text-truncate">{t('common:shares')}</span>}
                        <PieChart
                            data={mapData(activities?.shares)}
                            emptyStatePlaceholder="No data"
                            isLoading={isLoading} />
                    </div>
                    <div className="py-3 col-md-6 col-lg-3 text-center placeholder-glow">
                        {isLoading
                            ? <span className="placeholder fw-semi-bold c-grey-200 border-radius w-50" style={{ height: 20 }}>{t('common:previews')}</span>
                            : <span className="fw-semi-bold c-black-100 text-truncate">{t('common:previews')}</span>}
                        <PieChart
                            data={mapData(activities?.previews)}
                            emptyStatePlaceholder="No data"
                            isLoading={isLoading} />
                    </div>
                    <div className="py-3 col-md-6 col-lg-3 text-center placeholder-glow">
                        {isLoading
                            ? <span className="placeholder fw-semi-bold c-grey-200 border-radius w-50" style={{ height: 20 }}>{t('common:uploads')}</span>
                            : <span className="fw-semi-bold c-black-100 text-truncate">{t('common:uploads')}</span>}
                        <PieChart
                            data={mapData(activities?.uploads)}
                            emptyStatePlaceholder="No data"
                            isLoading={isLoading} />
                    </div>
                </div>
            </div>
            <div className="d-block d-md-none">
                <div className="d-flex">
                    <div className="col-8">
                        {t('common:show_activity')}
                    </div>
                    <div className="col-4 table-header">
                        <Dropdown className="w-100">
                            <Dropdown.Toggle className="d-flex align-items-center justify-content-end">
                                <span className="fw-bold text-truncate">
                                    {t(filterProperty)}
                                </span>
                                <img
                                    alt="arrow-down"
                                    src={`${process.env.PUBLIC_URL}/images/icons/arrow-down.svg`} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => setFilterProperty('downloads')}
                                    className={`${(filterProperty === 'downloads') ? 'active' : ''}`}>
                                    <div className="d-flex justify-content-between">
                                        <span className="text-truncate">{t('downloads')}</span>

                                        {
                                            filterProperty === 'downloads' &&
                                            <img
                                                className="ps-1"
                                                alt="check"
                                                src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                        }
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => setFilterProperty('shares')}
                                    className={`${(filterProperty === 'shares') ? 'active' : ''}`}>
                                    <div className="d-flex justify-content-between">
                                        <span className="text-truncate">{t('shares')}</span>

                                        {
                                            filterProperty === 'shares' &&
                                            <img
                                                className="ps-1"
                                                alt="check"
                                                src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                        }
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => setFilterProperty('previews')}
                                    className={`${(filterProperty === 'previews') ? 'active' : ''}`}>
                                    <div className="d-flex justify-content-between">
                                        <span className="text-truncate">{t('previews')}</span>

                                        {
                                            filterProperty === 'previews' &&
                                            <img
                                                className="ps-1"
                                                alt="check"
                                                src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                        }
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => setFilterProperty('uploads')}
                                    className={`${(filterProperty === 'uploads') ? 'active' : ''}`}>
                                    <div className="d-flex justify-content-between">
                                        <span className="text-truncate">{t('uploads')}</span>

                                        {
                                            filterProperty === 'uploads' &&
                                            <img
                                                className="ps-1"
                                                alt="check"
                                                src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                        }
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <PieChart
                            data={getDataByType(filterProperty)}
                            emptyStatePlaceholder="No data"
                            isLoading={isLoading} />
                    </div>
                </div>

            </div>
        </div>
    );
}

export default ActivityByAssetTypes;