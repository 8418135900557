import { useTranslation } from "react-i18next";
import { ResponsivePie } from '@nivo/pie';

import Tooltip from "../../../components/atoms/Tooltip";
import ExportFile from "../../../components/molecules/ExportFile";

import formatNumber from "../../../helpers/formatting/formatNumber";

const SharesActivityChart = ({ data, exportChart, isLoading, timePeriod }) => {
    const { t } = useTranslation(['common']);

    const isDataNotEmpty = () => {
        return data?.length > 0 && data.reduce((sum, item) => sum + item?.realValue, 0) > 0;
    }

    return (
        <div className="bc-white p-4 border-radius exportable" style={{ maxWidth: '100%' }}>
            {!isLoading && <>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between text-no-wrap" style={{ height: 40 }}>
                        <div className="d-flex align-items-center">
                            <div className="fw-semi-bold">{t('assets:shares_activity')}</div>
                            <Tooltip
                                anchor={`shares-activity-tooltip-anchor`}
                                description={t('assets:shares_activity_tooltip')} />
                        </div>
                        {isDataNotEmpty() &&
                            <span data-html2canvas-ignore>
                                <ExportFile
                                    exportFile={exportChart} />
                            </span>
                        }
                    </div>
                    <div className="col-12 c-black-100 d-none exportable-label">
                        {timePeriod}
                    </div>
                </div>
                {isDataNotEmpty() &&
                    <div className="d-flex align-items-center h-100 row">
                        <div className="col-12 col-md-6" style={{ height: 270 }}>
                            <ResponsivePie
                                data={data.filter(item => item.realValue > 0)}
                                margin={{ top: 24, right: 6, bottom: 34, left: 6 }}
                                innerRadius={0.65}
                                padAngle={2}
                                cornerRadius={8}
                                sortByValue={true}
                                enableArcLinkLabels={false}
                                colors={{ datum: 'data.color' }}
                                enableArcLabels={false}
                                activeOuterRadiusOffset={6}
                                tooltip={(input) => {
                                    let item = input.datum.data;
                                    return (
                                        <>
                                            {isDataNotEmpty() > 0 &&
                                                <div className="bc-white rounded-4 chart-tooltip-md p-3"
                                                    style={{ boxShadow: '0px 0px 24px 0px rgba(26, 38, 63, 0.10)' }}>
                                                    <div className="row align-items-center justify-content-between g-0">
                                                        <div className="col-9 d-flex align-items-center mb-2">
                                                            <div className="dot me-2" style={{ backgroundColor: item.color, width: 12 }}></div>
                                                            <span className="c-dark-blue fw-semi-bold">{t(item.label)}</span>
                                                        </div>
                                                        <div className="col-3 text-nowrap text-end mb-2">
                                                            <span className="c-dark-blue">{item.present.toFixed(2)} %</span>
                                                        </div>
                                                        <div className="col-12">
                                                            <span className="c-dark-blue">{formatNumber(item.realValue)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-6 d-flex flex-column">
                            {data?.map((item, index) => (
                                <div key={"activity_pie_chart" + index}>
                                    {
                                        <div className="d-flex align-items-center justify-content-between pb-2 mb-1">
                                            <div className="d-flex align-items-center">
                                                <div className="dot" style={{ backgroundColor: item.color }}></div>
                                                <span className="c-black-100 ps-2">{t(item.label)}</span>
                                            </div>
                                            <div className="text-end">
                                                <span className="c-black-100 ps-1">{formatNumber(item.realValue)}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                }
                {!isDataNotEmpty() &&
                    <div className="h-100">
                        <div className="d-flex flex-column justify-content-center align-items-center pt-5" style={{ height: 270 }}>
                            <img
                                alt="not data"
                                src={`${process.env.PUBLIC_URL}/images/empty/share-activity.svg`} />
                            <div className="c-dark-blue fw-semi-bold">{t("assets:empty_share_activity_message")}</div>
                            <div className="text-center">
                                <span className="c-dark-blue">
                                    {t("assets:empty_activity_decryption")}
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </>}
            {isLoading &&
                <div className="d-flex align-items-center h-100 row">
                    <div className="col-12 col-md-6" style={{ height: 310 }}>
                        <ResponsivePie
                            data={[{ label: "No data", value: 1, color: '#F0F2FA' }]}
                            margin={{ top: 24, right: 0, bottom: 34, left: 0 }}
                            innerRadius={0.65}
                            padAngle={2}
                            cornerRadius={8}
                            sortByValue={true}
                            enableArcLinkLabels={false}
                            colors={{
                                datum: 'data.color'
                            }}
                            enableArcLabels={false}
                            activeOuterRadiusOffset={6}
                            tooltip={() => (<></>)}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                        <div className="row">
                            <div className="col-12 placeholder-glow mb-4">
                                <div className="placeholder c-grey-200 rounded-4 w-50 mb-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default SharesActivityChart;