import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.scss';
import AuthInterceptor from "./interceptors/auth-interceptor";

AuthInterceptor();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
