import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Dropdown } from "react-bootstrap";

import formatNumber from "../../helpers/formatting/formatNumber";
import getAssetRedirectUrl from "../../helpers/formatting/getAssetRedirectUrl";
import { ASSETS_TABLE_CAPACITY, ASCENDING_SORT_ORDER, DESCENDING_SORT_ORDER } from "../../helpers/constants/constants";

const AssetsTableMobile = ({ data, isLoading, from, to, selectedOption }) => {
    const { t } = useTranslation('common');

    const [sortedData, setSortedData] = useState(data);
    const [sortProperty, setSortProperty] = useState("total");
    const [sortOrder, setSortOrder] = useState(DESCENDING_SORT_ORDER);

    const changeSortOrder = (order, property) => {
        const sortArray = type => {
            let sorted = order === DESCENDING_SORT_ORDER ? [...data].sort((a, b) => b[type] - a[type]) : [...data].sort((a, b) => a[type] - b[type]);
            const length = sorted.length;
            if (ASSETS_TABLE_CAPACITY - length > 0) {
                sorted = sorted.concat(emptyAssets.slice(0, ASSETS_TABLE_CAPACITY - length));
            }

            setSortedData(sorted);
        };

        setSortOrder(order);
        setSortProperty(property);

        if (data?.length) {
            sortArray(property);
        }
    }

    useEffect(() => {
        if (data?.length)
            changeSortOrder(sortOrder, sortProperty)
        else
            setSortedData([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const AssetRow = ({ index, style }) => (
        <div
            style={style}
            className={`row table-row border-top-grey-200 ${sortedData[index]?.isDeleted ? 'c-grey-800' : 'c-dark-blue'}`}>
            <div className="col-8 d-flex align-items-center">
                {
                    sortedData[index]?.type &&
                    <img
                        className={`ps-3 pe-1 ${sortedData[index]?.isDeleted && 'filter-gray'}`}
                        style={{ height: 20 }}
                        alt={`info-${sortedData[index]?.type?.toLowerCase()}`}
                        src={`${process.env.PUBLIC_URL}/images/icons/assets/${sortedData[index]?.type?.toLowerCase()}.svg`} />
                }

                {
                    sortedData[index]?.name && !sortedData[index]?.isDeleted &&
                    <a href={getAssetRedirectUrl(sortedData[index]?.id, from, to, selectedOption?.value)}
                        rel="noreferrer"
                        target="_blank"
                        className="c-dark-blue d-block text-truncate">
                        <span>{sortedData[index]?.name}</span>
                    </a>
                }

                {
                    sortedData[index]?.name && sortedData[index]?.isDeleted &&
                    <span className="d-block text-truncate user-select-none">{sortedData[index]?.name}</span>
                }
            </div>
            <div className="d-flex align-items-center col-4">
                {(sortedData.length && index >= 0) && formatNumber(sortedData[index][sortProperty])}
            </div>
        </div>
    )

    const SortIcon = () =>
    (
        <>
            <img
                alt="arrow-sort-desc"
                style={{ height: 18 }}
                className={`ps-1 ${sortOrder === ASCENDING_SORT_ORDER ? 'rotate-180' : 'rotate-back'}`}
                src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-desc.svg`} />
        </>
    );

    const ColumnHeader = ({ property }) =>
    (
        <div className="d-flex py-2" style={{ paddingRight: 10 }}>
            <div className="col-8 c-grey-800">
                <span className="ps-3">{t("asset_name")}</span>
            </div>
            <div className="col-4 column-header">
                <Dropdown className="w-100">
                    <Dropdown.Toggle className="d-flex align-items-center">
                        <span className={`${property === sortProperty ? 'current' : ''}`}>
                            <SortIcon property={property} />
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={() => changeSortOrder(DESCENDING_SORT_ORDER, sortProperty)}
                            className={`${(sortOrder === DESCENDING_SORT_ORDER) ? 'active' : ''}`}>
                            <img
                                alt="arrow-sort-desc"
                                className={`pe-1 rotate-180`}
                                src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                            {t('most_to_least')}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => changeSortOrder(ASCENDING_SORT_ORDER, sortProperty)}
                            className={`${(sortOrder === ASCENDING_SORT_ORDER) ? 'active' : ''}`}>
                            <img
                                alt="arrow-sort-asc"
                                className={`pe-1`}
                                src={`${process.env.PUBLIC_URL}/images/icons/arrow-sort-up.svg`} />
                            {t('least_to_most')}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );

    const PlaceholderRow = ({ width }) =>
    (
        <div className="row border-top-grey-200 text-sm h-100">
            <div className="placeholder-glow col-8 d-flex align-items-center">
                <span className={`placeholder c-grey-200 ps-3 border-radius w-${width}`}>&nbsp;</span>
            </div>
            <div className="col-4">
                <div className="row h-100">
                    <div className="placeholder-glow col-12 d-flex align-items-center">
                        <span className={`placeholder c-grey-200 border-radius w-${width}`}>&nbsp;</span>
                    </div>
                </div>
            </div>
        </div>
    );

    const EmptyRow = () =>
    (
        <>

            <div className="row h-100">
                <div className="col-3 d-flex align-items-center"></div>
                <div className="col-3 d-flex align-items-center"></div>
                <div className="col-3 d-flex align-items-center"></div>
                <div className="col-3 d-flex align-items-center"></div>
            </div>

        </>
    );

    const EmptyState = ({ asset }) =>
    (
        <>
            {sortedData?.length === 0 &&
                <div
                    className="d-flex flex-column justify-content-between px-3 position-relative"
                    style={{ height: '325px' }}>
                    <ColumnHeader property={asset} />
                    {
                        emptyAssets.map((_, index) => (
                            <div
                                key={index + 'empty-state-mobile'}
                                className={`row border-top-grey-200 text-sm h-100 ${index === emptyAssets.length - 1 && 'border-bottom-grey-200'}`}>
                                <EmptyRow />
                            </div>
                        ))
                    }
                    <div className="d-flex flex-column align-items-center justify-content-center position-absolute top-50 start-50 translate-middle">
                        <img
                            alt="page not found"
                            src={`${process.env.PUBLIC_URL}/images/no-documents.svg`} />
                        <div className="c-dark-blue fw-semi-bold">No Assets during this period</div>
                        <div className="text-center w-75">
                            <span className="c-dark-blue">
                                Select a different time range to view assets here
                            </span>
                        </div>
                    </div>

                </div>
            }
        </>
    );

    const AssetTab = ({ asset }) => (
        <>
            {sortedData?.length > 0 &&
                <div style={{ height: '325px', position: 'relative' }}>
                    <ColumnHeader property={asset} />
                    <AutoSizer>
                        {({ height, width }) => (
                            <List
                                className="c-grey-800 fade-in"
                                height={height}
                                style={{ overflowX: 'hidden' }}
                                itemCount={sortedData?.length}
                                itemSize={46}
                                width={width}>
                                {AssetRow}
                            </List>
                        )}
                    </AutoSizer>
                </div>
            }
            <EmptyState asset={asset} />
        </>
    );

    const emptyAsset = {
        downloads: null,
        id: null,
        name: null,
        previews: null,
        shares: null,
        total: null
    };

    const emptyAssets = [emptyAsset, emptyAsset, emptyAsset, emptyAsset, emptyAsset, emptyAsset, emptyAsset];

    return (
        <>
            {
                !isLoading &&
                <>
                    <div className="my-3">
                        <div className="pb-3">
                            <div className="d-flex py-2 mb-2" style={{ paddingRight: 10 }}>
                                <div className="col-7">
                                    {t('show_activity')}
                                </div>
                                <div className="col-5 table-header">
                                    <Dropdown className="w-100">
                                        <Dropdown.Toggle className="d-flex align-items-center justify-content-end">
                                            <span className="bc-grey-200 border-radius ps-2">
                                                <span className="fw-bold text-truncate">
                                                    {t(sortProperty)}
                                                </span>
                                                <img
                                                    alt="arrow-down"
                                                    src={`${process.env.PUBLIC_URL}/images/icons/arrow-down.svg`} />
                                            </span>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => changeSortOrder(sortOrder, 'total')}
                                                className={`${(sortProperty === 'total') ? 'active' : ''}`}>
                                                <div className="d-flex justify-content-between">
                                                    <span className="text-truncate">{t('total')}</span>

                                                    {
                                                        sortProperty === 'total' &&
                                                        <img
                                                            className="ps-1"
                                                            alt="check"
                                                            src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                    }
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => { changeSortOrder(sortOrder, 'downloads') }}
                                                className={`${(sortProperty === 'downloads') ? 'active' : ''}`}>
                                                <div className="d-flex justify-content-between">
                                                    <span className="text-truncate">{t('downloads')}</span>

                                                    {
                                                        sortProperty === 'downloads' &&
                                                        <img
                                                            className="ps-1"
                                                            alt="check"
                                                            src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                    }
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => { changeSortOrder(sortOrder, 'shares') }}
                                                className={`${(sortProperty === 'shares') ? 'active' : ''}`}>
                                                <div className="d-flex justify-content-between">
                                                    <span className="text-truncate">{t('shares')}</span>

                                                    {
                                                        sortProperty === 'shares' &&
                                                        <img
                                                            className="ps-1"
                                                            alt="check"
                                                            src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                    }
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => { changeSortOrder(sortOrder, 'previews') }}
                                                className={`${(sortProperty === 'previews') ? 'active' : ''}`}>
                                                <div className="d-flex justify-content-between">
                                                    <span className="text-truncate">{t('previews')}</span>

                                                    {
                                                        sortProperty === 'previews' &&
                                                        <img
                                                            className="ps-1"
                                                            alt="check"
                                                            src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                    }
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <AssetTab asset="total" />
                        </div>
                    </div>
                </>
            }
            {
                isLoading &&
                <>
                    <div className="my-3">
                        <div className="pb-3">
                            <div className="d-flex py-2 mb-2" style={{ paddingRight: 10 }}>
                                <div className="placeholder-glow col-8">
                                    <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                </div>
                                <div className="placeholder-glow col-4">
                                    <span className="placeholder c-grey-200 border-radius w-75">&nbsp;</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-between" style={{ height: 325 }}>
                            <PlaceholderRow width={50} />
                            <PlaceholderRow width={75} />
                            <PlaceholderRow width={50} />
                            <PlaceholderRow width={75} />
                            <PlaceholderRow width={50} />
                            <PlaceholderRow width={75} />
                            <PlaceholderRow width={50} />
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default AssetsTableMobile;