import { ResponsivePie } from '@nivo/pie';
import formatNumber from "../../helpers/formatting/formatNumber";

const PieChartWithLegend = ({ data, emptyStatePlaceholder, isLoading }) => {

    const emptyState = {
        colors: ['#F0F2FA'],
        data: [{ id: emptyStatePlaceholder, value: 1, color: '#F0F2FA' }]
    }

    return (
        <>
            {!isLoading &&
                <>
                    <div className="d-flex align-items-center h-100 row">
                        <div className={`col-12 col-md-6`} style={{ height: 300 }}>
                            <ResponsivePie
                                data={data?.length ? data : emptyState.data}
                                margin={{ top: 24, right: 6, bottom: 34, left: 6 }}
                                innerRadius={0.65}
                                padAngle={2}
                                cornerRadius={8}
                                sortByValue={true}
                                enableArcLinkLabels={false}
                                colors={{
                                    datum: 'data.color'
                                }}
                                enableArcLabels={false}
                                activeOuterRadiusOffset={6}
                                tooltip={(input) => {
                                    return (
                                        <>
                                            {data?.length > 0 &&
                                                <div className="bc-white border-radius p-3 chart-tooltip" style={{ boxShadow: '0px 0px 24px 0px rgba(26, 38, 63, 0.10)', minWidth: '225px' }}>
                                                    <div className="d-flex justify-content-between mb-2 w-100">
                                                        <div className="d-flex align-items-center justify-content-between w-100">
                                                            <div>
                                                                <img
                                                                    alt={`info-${input?.datum?.id?.toLowerCase()}`}
                                                                    src={`${process.env.PUBLIC_URL}/images/assets/${input?.datum?.id?.toLowerCase()}.svg`} />
                                                                <span className="fw-bold px-2 c-black-100">{input?.datum?.data?.label}</span>
                                                            </div>

                                                            <div>{formatNumber(input?.datum?.data?.percentage?.toFixed(2))}&nbsp;%</div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <span className="c-grey-800">
                                                            {formatNumber(input.datum.data.realValue)}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                                }}
                            />
                        </div>

                        <div className="col-12 col-md-6 d-flex flex-column mb-3 mb-md-0">
                            {data?.length ?
                                <>
                                    {
                                        data?.map((item, index) => (
                                            <div key={item.id + index}>
                                                {
                                                    <div className="row align-items-center justify-content-between pb-2 mb-1 ps-3 ps-md-0">
                                                        <div className="col-6 d-flex align-items-center">
                                                            <img
                                                                alt={`info-${item?.id?.toLowerCase()}`}
                                                                className={`px-1`}
                                                                src={`${process.env.PUBLIC_URL}/images/assets/${item?.id?.toLowerCase()}.svg`} />
                                                            <span className="c-black-100 px-2">{item.label}</span>
                                                        </div>
                                                        <div className="col-6 d-flex align-items-center">
                                                            <span className="ps-5 ms-3 ms-md-0 dark-blue">{formatNumber(item.realValue)}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                </>
                                :
                                <div className="d-flex justify-content-center h-100">
                                    <div className="d-flex align-items-center pb-2 mb-1 w-100">
                                        <div className="dot mx-2" style={{ backgroundColor: emptyState.colors[0] }}></div>
                                        <span className="text-sm fw-light">{emptyState.data[0].id}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
            {isLoading &&
                <div className="d-flex align-items-center h-100 row">
                    <div className={`col-12 col-md-6`} style={{ height: 300 }}>
                        <ResponsivePie
                            data={emptyState.data}
                            margin={{ top: 24, right: 0, bottom: 34, left: 0 }}
                            innerRadius={0.65}
                            padAngle={2}
                            cornerRadius={8}
                            sortByValue={true}
                            enableArcLinkLabels={false}
                            colors={{
                                datum: 'data.color'
                            }}
                            enableArcLabels={false}
                            activeOuterRadiusOffset={6}
                            tooltip={() => (<></>)}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                        <div className="row">
                            <div className="col-12 placeholder-glow mb-4">
                                <div className="placeholder c-grey-200 border-radius w-50 mb-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default PieChartWithLegend;