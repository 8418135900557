import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Tooltip from "../../../components/atoms/Tooltip";
import EmptyPage from "../../../components/atoms/EmptyPage";
import LineChart from "../../../components/organisms/LineChart";
import ExportFile from "../../../components/molecules/ExportFile";
import LineChartSkeleton from "../../../components/atoms/LineChartSkeleton";

import selectDate from "../../../helpers/dates/selectDate";
import formatNumber from "../../../helpers/formatting/formatNumber";
import getYearTransitions from "../../../helpers/dates/getYearTransitions";
import generateIntervals from "../../../helpers/formatting/generateIntervals";
import getTooltipDateFormat from "../../../helpers/dates/getTooltipDateFormat";
import calculateDaysBetween from "../../../helpers/dates/calculateDaysBetween";

const AssetsUploadChart = ({ data, isLoading, exportChart }) => {
    const { t } = useTranslation();

    //Lines color codes bright-blue 
    const lineColors = ['#1335AF'];
    const [dataSet, setData] = useState([]);
    const [min, setMin] = useState();
    const [max, setMax] = useState();
    const [gridYValues, setGridYValues] = useState();
    const [interval, setInterval] = useState();
    const [yearTransitions, setYearTransitions] = useState([]);

    useEffect(() => {
        const dataList = [];

        if (!data || data.length === 0) {
            setData(dataList);
            return;
        }
        const calcYearTransitions = getYearTransitions(data);
        setYearTransitions(calcYearTransitions);
        const calcInterval = calculateDaysBetween(data[0]?.intervalStart, data[0]?.intervalEnd);
        setInterval(calcInterval);
        dataList.push({
            id: t("dam:uploads"),
            color: lineColors[0],
            data: data.map((m, index) => ({
                "x": selectDate(m.intervalStart, m.intervalEnd, index === data?.length - 1),
                "y": m.total
            }))
        });
        setData(dataList);
        const combinedArray = [...data.map(v => v.total)];
        let generatedIntervals = generateIntervals(combinedArray);
        setGridYValues(generatedIntervals);
        setMin(generatedIntervals[0]);
        const arrayMax = Math.max(...combinedArray);
        setMax(arrayMax > generatedIntervals[4] ? arrayMax : generatedIntervals[4]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const tooltipRender = (slice) => {
        if (!slice) return;
        const point = data[slice.points[0].index]

        const calcRange = getTooltipDateFormat(point, interval, yearTransitions);
        let dateRange;
        if (calcRange?.hours)
            dateRange = calcRange?.hours;
        else if (calcRange.to)
            if (t('date:' + calcRange.from[0], { date: new Date(calcRange.from[1]) }) === t('date:' + calcRange.to[0], { date: new Date(calcRange.to[1]) }))
                dateRange = t('date:' + calcRange.from[0], { date: new Date(calcRange.from[1]) });
            else
                dateRange = t('date:' + calcRange.from[0], { date: new Date(calcRange.from[1]) }) + " - " + t('date:' + calcRange.to[0], { date: new Date(calcRange.to[1]) });
        else
            dateRange = t('date:' + calcRange.from[0], { date: new Date(calcRange.from[1]) });

        return (
            <div className="bc-white border-radius box-shadow chart-tooltip"
                style={{ minWidth: 250 }} >
                <div className="p-3">
                    <div className="c-grey-800 text-sm">{dateRange}</div>
                    <div className="d-flex justify-content-between">
                        <span>
                            <span className="dot" style={{ backgroundColor: slice.points[0]?.serieColor }}></span>
                            <span className="fw-bold ps-2 c-black-100">{t("dam:uploads")}</span>
                        </span>
                        <span>{formatNumber(slice?.points[0]?.data?.y)}</span>
                    </div>
                </div>
            </div>
        );
    }

    return (<>
        <div className="bc-white p-4 pt-3 border-radius mt-4 exportable">
            {!isLoading &&
                <div className="row g-0 align-items-center">
                    <div className="col-8 col-md-6 d-flex text-nowrap">
                        <span className="fw-semi-bold c-black-100"> {t('dam:assets_upload')}</span>
                        <Tooltip
                            anchor={`tooltip-anchor-uploads`}
                            description={t('dam:assets_upload_tooltip')} />
                    </div>
                    {dataSet?.length > 0 && <>
                        <div className="col-4 col-md-6">
                            <div className="d-block d-md-flex align-items-center justify-content-end">
                                <div className="d-flex justify-content-end align-items-center">
                                    <div className="dot mx-2 d-none d-md-flex" style={{ backgroundColor: lineColors[0] }}></div>
                                    <span className="c-black-100 fw-light pe-md-3 d-none d-md-flex">{t("dam:uploads")}</span>
                                    <span data-html2canvas-ignore>
                                        <ExportFile
                                            exportFile={exportChart} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex d-md-none">
                            <span className="dot mx-2" style={{ backgroundColor: lineColors[0] }}></span>
                            <span className="c-black-100 fw-light pe-md-3">{t("dam:uploads")}</span>
                        </div>
                    </>}
                    <div className="col-12 c-black-100 d-none exportable-label">
                        {t('date:day_month_year', { date: new Date(data?.[0]?.intervalStart) })}
                        <span> - </span>
                        {t('date:day_month_year', { date: new Date(data?.[data?.length - 1]?.intervalStart) })}
                    </div>
                </div>
            }
            {!isLoading && dataSet?.length > 0 &&
                < LineChart
                    data={dataSet}
                    min={min}
                    max={max}
                    gridYValues={gridYValues}
                    tooltipRender={tooltipRender}
                    height={330}
                    interval={interval}
                    yearTransitions={yearTransitions.map(y => t('date:day_month_year', { date: new Date(y) }))}
                />
            }
            {!isLoading && dataSet?.length === 0 &&
                < EmptyPage
                    header={t("dam:no_uploads")}
                    message={t("dam:no_data_message")}
                    img={`${process.env.PUBLIC_URL}/images/empty/assets-upload.svg`}
                />
            }
            {isLoading &&
                < LineChartSkeleton />
            }
        </div>
    </>
    );
}

export default AssetsUploadChart;