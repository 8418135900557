export const Colors = {
    darkBlue: '#1335AF',
    blue: '#1988D8',
    lightBlue: '#69ADDE',
    red: '#D73A02',
    orange: '#F9662D',
    lightOrange: '#FF8C61',
    green: '#3F7A1A'
};

export default class COLORS { };