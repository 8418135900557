import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

import AssetsService from "../../../services/assets-service";

import Tooltip from "../../../components/atoms/Tooltip";
import AssetsTable from "../../../components/organisms/AssetsTable";
import AssetsTableMobile from "../../../components/organisms/AssetsTableMobile";

import { TENANT_ID_KEY } from "../../../helpers/constants/constants";

const assetsService = AssetsService();
const AssetsActivity = ({ activities, isLoading, from, to, selectedOption }) => {

    const { t } = useTranslation(['assets', 'common', 'dam', 'date']);
    const csvLink = useRef();
    const didMount = useRef(false);

    const headers = [
        {
            label: t("common:asset_status"),
            key: "assetStatus"
        },
        {
            label: t("common:asset_type"),
            key: "assetType"
        },
        {
            label: t("common:asset_name"),
            key: "assetName"
        },
        {
            label: t("common:total"),
            key: "total"
        },
        {
            label: t("common:downloads"),
            key: "downloads"
        },
        {
            label: t("common:shares"),
            key: "shares"
        },
        {
            label: t("common:previews"),
            key: "previews"
        }
    ];

    const [activitiesReport, setActivitiesReport] = useState([]);
    const [isActivitiesReportLoading, setIsActivitiesReportLoading] = useState(false);

    useEffect(() => {
        assetsService.init();

        return () => {
            assetsService.dispose();
        }
    }, [])

    const downloadActivities = () => {
        let tenant = window.localStorage.getItem(TENANT_ID_KEY);

        setIsActivitiesReportLoading(true);
        assetsService
            .getActivityReport(tenant, from, to)
            .then((response) => {
                setActivitiesReport(response.data.map((asset => (
                    {
                        ...asset,
                        assetStatus: asset.isDeleted ? t('common:deleted') : t('common:active'),
                        assetType: t("assets:" + asset.assetType)
                    }))));
            })
            .finally(() => {
                setIsActivitiesReportLoading(false);
            });
    }

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }

        if (activitiesReport.length > 0) {
            csvLink.current.link.click();
        }
    }, [activitiesReport]);

    return (
        <div className="bc-white p-4 pt-3 border-radius mt-4">
            {
                !isLoading &&
                <>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <span className="fw-semi-bold c-black-100 text-truncate">{t('dam:assets_activity')}</span>
                            <Tooltip
                                anchor={`tooltip-anchor-assets-activity`}
                                description={t('dam:assets_activity_description')} />
                        </div>
                        {activities && activities.length > 0 &&
                            <button
                                className="btn-transparent d-flex align-items-center"
                                disabled={isActivitiesReportLoading}
                                onClick={() => downloadActivities()}>
                                <img
                                    alt="download"
                                    className={`pe-2`}
                                    style={{ width: 25 }}
                                    src={`${process.env.PUBLIC_URL}/images/icons/${isActivitiesReportLoading ? 'loader' : 'download'}.svg`} />
                                <span className="c-dark-blue">{t('common:export')}</span>
                            </button>
                        }
                    </div>

                    <CSVLink
                        data={activitiesReport}
                        filename={`${t('assets_report')} ${t('date:full_date', { date: new Date(from) })} - ${t('date:full_date', { date: new Date(to) })}.csv`}
                        className="hidden"
                        ref={csvLink}
                        headers={headers}
                        enclosingCharacter=""
                        target="_blank" />
                </>

            }
            {
                isLoading &&
                <>
                    <div className="d-none d-md-flex align-items-center placeholder-glow">
                        <span className="placeholder c-grey-200 border-radius w-25">&nbsp</span>
                    </div>
                    <div className="d-flex d-md-none align-items-center placeholder-glow">
                        <span className="placeholder c-grey-200 border-radius w-50">&nbsp</span>
                    </div>
                </>
            }
            <div className="d-none d-md-block w-100">
                <AssetsTable
                    data={activities}
                    isLoading={isLoading}
                    from={from}
                    to={to}
                    selectedOption={selectedOption} />
            </div>

            <div className="d-block d-md-none">
                <AssetsTableMobile
                    data={activities}
                    isLoading={isLoading}
                    from={from}
                    to={to}
                    selectedOption={selectedOption} />
            </div>

        </div>
    );
}

export default AssetsActivity;