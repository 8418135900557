const formatBarCharAsset = (id, assetType, count, name, isDeleted) => {
    return {
        id: id,
        assetType: assetType,
        count: count,
        name: name,
        isDeleted: isDeleted
    }
}
    
export default formatBarCharAsset;