import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import '../../node_modules/moment/locale/fr';

import assets_en from "../locales/en/assets.json";
import assets_fr from "../locales/fr/assets.json";
import assets_es from "../locales/es/assets.json";

import common_en from "../locales/en/common.json";
import common_fr from "../locales/fr/common.json";
import common_es from "../locales/es/common.json";

import dam_en from "../locales/en/dam.json";
import dam_fr from "../locales/fr/dam.json";
import dam_es from "../locales/es/dam.json";

import errors_en from "../locales/en/errors.json";
import errors_fr from "../locales/fr/errors.json";
import errors_es from "../locales/es/errors.json";

import header_en from "../locales/en/header.json";
import header_fr from "../locales/fr/header.json";
import header_es from "../locales/es/header.json";

import footer_en from "../locales/en/footer.json";
import footer_fr from "../locales/fr/footer.json";
import footer_es from "../locales/es/footer.json";

import terms_fr from "../locales/fr/terms.json";
import terms_es from "../locales/es/terms.json";
import terms_en from "../locales/en/terms.json";

import date_fr from "../locales/fr/date.json";
import date_es from "../locales/es/date.json";
import date_en from "../locales/en/date.json";

import users_fr from "../locales/fr/users.json";
import users_es from "../locales/es/users.json";
import users_en from "../locales/en/users.json";

const resources = {
    en: {
        assets: assets_en,
        common: common_en,
        header: header_en,
        errors: errors_en,
        dam: dam_en,
        footer: footer_en,
        terms: terms_en,
        date: date_en,
        users: users_en
    },
    fr: {
        assets: assets_fr,
        common: common_fr,
        header: header_fr,
        errors: errors_fr,
        dam: dam_fr,
        footer: footer_fr,
        terms: terms_fr,
        date: date_fr,
        users: users_fr
    },
    es: {
        assets: assets_es,
        common: common_es,
        header: header_es,
        errors: errors_es,
        dam: dam_es,
        footer: footer_es,
        terms: terms_es,
        date: date_es,
        users: users_es
    }
};

i18n.use(initReactI18next).init({
    resources: resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
        format: function (value, format, lng) {
            if(value instanceof Date) {
                var date = moment(value);
                date.locale(lng);
                return date.format(format);
            }
            return value;
        }
    },
});

export default i18n;