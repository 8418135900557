import axios from "axios";

function TenantsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const get = () => axios
        .get('/tenants', config())
        .catch(error => error);

    const checkAccess = (tenantId) => axios
        .get(`/tenants/${tenantId}/access`, config())
        .catch(error => error);

    return {
        init,
        dispose,
        get,
        checkAccess
    };
}

export default TenantsService;