import { ResponsivePie } from '@nivo/pie';
import formatNumber from "../../helpers/formatting/formatNumber";
import { animated } from '@react-spring/web'

const PieChart = ({ data, emptyStatePlaceholder, isLoading }) => {

    const emptyState = {
        colors: ['#F0F2FA'],
        data: [{ id: emptyStatePlaceholder, value: 0.1, color: '#F0F2FA' }]
    }

    return (
        <>
            <div className="d-flex align-items-center h-100 row">
                <div className="col-12" style={{ height: 260 }}>
                    <ResponsivePie
                        data={data?.length && !isLoading ? data : emptyState.data}
                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                        innerRadius={0.55}
                        padAngle={2}
                        cornerRadius={8}
                        sortByValue={true}
                        arcLabelsSkipAngle={20}
                        arcLabelsRadiusOffset={0.55}
                        arcLabelsTextColor="#fff"
                        arcLabelsComponent={({
                            datum,
                            label,
                            style
                        }) => <animated.g transform={style.transform} style={{ pointerEvents: 'none' }}>
                                {data?.length > 0 && !isLoading &&
                                    <text textAnchor="middle" dominantBaseline="auto" fill={style.textColor}
                                        style={{
                                            fontSize: 12,
                                            fontWeight: 600
                                        }}>
                                        {data?.length > 0 && datum.data.realValue > emptyState.data[0].value ? formatNumber(datum.data.realValue) : ''}
                                    </text>
                                }
                            </animated.g>
                        }
                        enableArcLinkLabels={false}
                        colors={{
                            datum: 'data.color'
                        }}
                        enableArcLabels={true}
                        activeOuterRadiusOffset={6}
                        tooltip={(input) => {
                            return (
                                <>
                                    {data?.length > 0 && !isLoading &&
                                        <div className="bc-white border-radius p-3 chart-tooltip" style={{ boxShadow: '0px 0px 24px 0px rgba(26, 38, 63, 0.10)', minWidth: '225px' }}>
                                            <div className="d-flex justify-content-between my-2 w-100">
                                                <div className="d-flex align-items-center justify-content-between w-100">
                                                    <div>
                                                        <img
                                                            alt={`info-${input?.datum?.id?.toLowerCase()}`}
                                                            src={`${process.env.PUBLIC_URL}/images/assets/${input?.datum?.id?.toLowerCase()}.svg`} />
                                                        <span className="fw-bold px-2 c-black-100">{input?.datum?.data?.label}</span>
                                                    </div>

                                                    <div>{formatNumber(input.datum.data.realValue)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            )
                        }}
                    />
                </div>
                {
                    data?.length <= 0 &&
                    <div className="d-none d-md-flex justify-content-center align-items-center">
                        <div className="dot mx-2" style={{ backgroundColor: emptyState.data[0].color }}></div>
                        <span className="c-black-100 fw-light pe-3">{emptyStatePlaceholder}</span>
                    </div>
                }

                {data?.length > 0 && !isLoading &&
                    <div className="d-none d-md-flex align-items-center justify-content-center" style={{ height: 22.85 }}>
                    </div>
                }

                {
                    isLoading
                    && <div className="d-none d-md-flex align-items-center justify-content-center placeholder-glow" style={{ height: 22.85 }}>
                        <span className="placeholder c-grey-200 border-radius w-50">&nbsp</span>
                    </div>}
            </div >
        </>
    );
}

export default PieChart;