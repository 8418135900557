import { useTranslation } from "react-i18next";

import Footer from "../../core/Footer";

const SessionExpiredPage = () => {
    const { t } = useTranslation('errors');

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img
                    alt="session expired"
                    src={`${process.env.PUBLIC_URL}/images/errors/session-expired.svg`} />
                <h1 className="c-dark-blue fw-semi-bold text-center mb-2">{t("session_expired")}</h1>
                <div className="text-center w-75">
                    <span className="c-dark-blue">
                        {t("session_content")}
                    </span>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default SessionExpiredPage;