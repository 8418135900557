const Arrow = ({ turnOn }) => {
    return (
        <span>
            <img
                className={`${!turnOn && 'open-accordion'}`}
                alt="Arrow"
                style={{ height: 16, width: 16 }}
                src={`${process.env.PUBLIC_URL}/images/icons/arrow-down.svg`} />
        </span>
    );
}

export default Arrow;