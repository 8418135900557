import { Outlet, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import Footer from "./Footer";
import LoadingPage from "../components/organisms/LoadingPage";
import AuthHandler from "../services/common/auth-handler";

const authHandler = AuthHandler();
const AuthDamRoute = () => {
    const location = useLocation();
    authHandler.retrieve();
    return (
        authHandler.hasTenantAccess()
            ? <>
                <Outlet />
                <Footer />
            </>
            : (!authHandler.isAuthLoading() ?
                <Navigate
                    to={{
                        pathname: '/secure',
                        state: { redirectUrl: location.pathname }
                    }}
                />
                : <LoadingPage />
            )
    )

};

export default AuthDamRoute;