import { ASCENDING_SORT_ORDER } from "../constants/constants";

const filterAssetsByEventTypes = (items, assetType, sortOrder) => {
    let filteredArr;
    if(!assetType?.id) filteredArr = items.filter(obj => obj.count > 0)
    else filteredArr = items.filter(obj => obj.assetType === assetType.selector && obj.count > 0);
    let sortedArr = filteredArr.sort((a, b) => sortOrder === ASCENDING_SORT_ORDER ? a.count - b.count : b.count - a.count);
    return sortedArr.slice(0, 200);
};

export default filterAssetsByEventTypes;