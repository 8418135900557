import { jwtDecode } from "jwt-decode";
import {
    ACCESS_TOKEN_KEY,
    LANGUAGE_KEY,
    TENANT_ID_KEY,
    DAM_REPORTING_DATE_KEY,
    USER_REPORTING_DATE_KEY,
    REDIRECT_TOKEN_KEY,
    ASSET_ID_KEY,
    ASSET_REPORTING_ROUTE_PATH
} from "../../helpers/constants/constants";
import TenantsService from "../tenants-service";
import AssetsService from "../assets-service";
import isUUID from "../../helpers/validation/isUUID";

const tenantsService = TenantsService();
const assetsService = AssetsService();
const AuthHandler = () => {

    const AUTH_CHANGE_EVENT = 'auth';
    const AUTH_ROUTE_PATH = '/secure';

    let isLoading = false;

    const getSavedAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY);

    const reset = () => {
        window.localStorage.removeItem(ACCESS_TOKEN_KEY);
        window.localStorage.removeItem(TENANT_ID_KEY);
        window.localStorage.removeItem(LANGUAGE_KEY);
        window.localStorage.removeItem(DAM_REPORTING_DATE_KEY);
        window.localStorage.removeItem(USER_REPORTING_DATE_KEY);
        window.localStorage.removeItem(ASSET_ID_KEY);
        window.dispatchEvent(new Event(AUTH_CHANGE_EVENT));
    }

    const retrieve = () => {
        isLoading = true;
        tenantsService.init();
        let token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
        let tenantId = window.localStorage.getItem(TENANT_ID_KEY);

        let params = (new URL(document.location)).searchParams;
        let accessTokenParam = params.get("access-token");
        let tenantIdParam = params.get("tenant-id");

        if (accessTokenParam) token = accessTokenParam;
        if (tenantIdParam) tenantId = tenantIdParam;

        try {
            if (!!!jwtDecode(token) && !isUUID(tenantId)) {
                if (window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
            }
        }
        catch (e) {
            if (window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
        }

        if (token && tenantId) {
            window.localStorage.setItem(REDIRECT_TOKEN_KEY, token);
            tenantsService
                .checkAccess(tenantId)
                .then(response => {
                    if (response.data) {
                        window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
                        window.localStorage.setItem(TENANT_ID_KEY, tenantId);

                        if (accessTokenParam && tenantIdParam) {
                            window.history.pushState({}, document.title, '/');
                            window.location.reload();
                        }
                    } else {
                        if (response.code !== "ERR_CANCELED" && window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
                    }
                })
                .finally(() => {
                    tenantsService.dispose();
                    window.localStorage.removeItem(REDIRECT_TOKEN_KEY);
                    isLoading = false;
                });
        } else {
            if (window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
        }
    }

    const retrieveAsset = () => {
        isLoading = true;

        assetsService.init();
        let token = window.localStorage.getItem(ACCESS_TOKEN_KEY);
        let assetId = window.localStorage.getItem(ASSET_ID_KEY);

        let params = (new URL(document.location)).searchParams;
        let accessTokenParam = params.get("access-token");
        let assetIdParam = params.get("asset-id");

        if (accessTokenParam) token = accessTokenParam;
        if (assetIdParam) assetId = assetIdParam;

        try {
            if (!!!jwtDecode(token) && !isUUID(assetId)) {
                if (window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
            }
        }
        catch (e) {
            if (window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
        }

        if (token && assetId) {
            window.localStorage.setItem(REDIRECT_TOKEN_KEY, token);
            assetsService
                .checkAccess(assetId)
                .then(response => {
                    if (response.data) {
                        window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
                        window.localStorage.setItem(ASSET_ID_KEY, assetId);

                        if (accessTokenParam && assetIdParam) {
                            window.history.pushState({}, document.title, ASSET_REPORTING_ROUTE_PATH);
                            window.location.reload();
                        }
                    } else {
                        if (response.code !== "ERR_CANCELED" && window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
                    }
                })
                .finally(() => {
                    assetsService.dispose();
                    window.localStorage.removeItem(REDIRECT_TOKEN_KEY);
                    isLoading = false;
                });
        } else {
            if (window.location.pathname !== AUTH_ROUTE_PATH) window.location.href = AUTH_ROUTE_PATH;
        }
    }

    const isAuthenticated = () => !!getSavedAccessToken() && !!jwtDecode(getSavedAccessToken());

    const hasTenantAccess = () => {
        let tenantId = window.localStorage.getItem(TENANT_ID_KEY);
        return !!getSavedAccessToken() && !!jwtDecode(getSavedAccessToken()) && isUUID(tenantId);
    }

    const hasAssetAccess = () => {
        let assetId = window.localStorage.getItem(ASSET_ID_KEY);
        return !!getSavedAccessToken() && !!jwtDecode(getSavedAccessToken()) && isUUID(assetId);
    }

    const isAuthLoading = () => {
        return isLoading;
    }

    const getAuth = () => ({
        isAuthenticated: isAuthenticated()
    });

    const getCurrentLanguage = () => {
        var token = getSavedAccessToken();
        if (!token) return;

        var decodedToken = jwtDecode(token);
        return decodedToken?.language;
    }

    const subscribe = (listener, silent = true) => {
        if (!listener) return () => { };
        const callback = () => listener(getAuth());
        window.addEventListener(AUTH_CHANGE_EVENT, callback);
        if (!silent) callback();
        return () => window.removeEventListener(AUTH_CHANGE_EVENT, callback);
    }

    return {
        isAuthenticated,
        hasTenantAccess,
        hasAssetAccess,
        isAuthLoading,
        reset,
        retrieve,
        retrieveAsset,
        getAuth,
        subscribe,
        getCurrentLanguage
    };
}

export default AuthHandler;