import { useTranslation } from "react-i18next";

import Footer from "../../core/Footer";
import AuthHandler from "../../services/common/auth-handler";
import { useEffect } from "react";

const authHandler = AuthHandler();
const UnauthorizedPage = () => {
    const { t } = useTranslation('errors');

    useEffect(() => {
        authHandler.reset();
    }, [])

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img
                    alt="unauthorized"
                    src={`${process.env.PUBLIC_URL}/images/errors/unauthorized.svg`} />
                <h1 className="c-dark-blue fw-semi-bold text-center mb-2">{t("unauthorized")}</h1>
                <div className="text-center w-75">
                    <span className="c-dark-blue">
                        {t("unauthorized_content")}
                    </span>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default UnauthorizedPage;