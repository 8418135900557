import { useState } from "react";
import { Modal } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from "react-i18next";

import { JPEG, PNG } from "../../helpers/constants/constants";

const ExportFile = ({ exportFile }) => {
    const { t } = useTranslation(['common']);

    const [show, setShow] = useState(false);

    return (
        <Dropdown>
            <Dropdown.Toggle className="border-0 btn-link bg-transparent text-decoration-none p-0">
                <div
                    onClick={() => setShow(true)}
                    className="btn-transparent d-flex align-items-center px-3 py-2">
                    <img
                        alt="download"
                        className="pe-2"
                        src={`${process.env.PUBLIC_URL}/images/icons/image-download.svg`} />
                    <span className="d-none d-lg-block">{t('export')}</span>
                </div>
            </Dropdown.Toggle>
            <Modal show={show} onHide={() => setShow(false)}>
                <Dropdown.Menu style={{ marginTop: -25 }} className="border-radius dropdown-menu-width">
                    <div className="p-1">
                        <h6 className="mb-4 ps-3">{t("selected_file_format")}</h6>
                        <Dropdown.Item
                            className="bg-transparent text-decoration-none modal-link"
                            onClick={() => { exportFile(PNG); setShow(false) }}>
                            <div className="d-flex">
                                <div className="row align-items-center border border-radius mb-3 g-0 p-3">
                                    <div className="col-2">
                                        <img alt="png" src={`${process.env.PUBLIC_URL}/images/assets/png-image.svg`} />
                                    </div>
                                    <div className="col-8 py-3 ps-3 modal-link">
                                        <h6>.png</h6>
                                        <div className="text-wrap">
                                            {t("png_format_message")}
                                        </div>
                                    </div>
                                    <div className="col-2 ps-3">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="fileFormat"
                                                id=".png"
                                            />
                                            <label className="form-check-label" htmlFor=".png"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                            className="bg-transparent text-decoration-none "
                            onClick={() => { exportFile(JPEG); setShow(false) }}>
                            <div className="d-flex">
                                <div className="row align-items-lg-center border border-radius g-0 p-3">
                                    <div className="col-2">
                                        <img alt="jpeg" src={`${process.env.PUBLIC_URL}/images/assets/jpeg-image.svg`} />
                                    </div>
                                    <div className="col-8 py-2 ps-3 modal-link">
                                        <h6>.jpeg</h6>
                                        <div className="text-wrap">
                                            {t("jpeg_format_message")}
                                        </div>
                                    </div>
                                    <div className="col-2 ps-3">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="fileFormat"
                                                id=".jpeg"
                                            />
                                            <label className="form-check-label" htmlFor=".jpeg"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Modal>
        </Dropdown >
    );
}

export default ExportFile;