import Tooltip from "../atoms/Tooltip";
import Trend from "../atoms/Trend";

const Tile = ({ name, title, value, value1, value2, description, size = 'lg', isLoading }) => {
    return (
        <div className="bc-white p-4  box-shadow border-radius h-100">
            {
                !isLoading &&
                <>
                    <div className="d-flex align-items-center mb-2">
                        <span className="c-grey-800 text-truncate">{title}</span>
                        {description &&
                            <Tooltip
                                anchor={`tooltip-anchor-${name}`}
                                description={description} />
                        }
                    </div>
                    {size === 'lg' &&
                        <div className="d-flex align-items-center justify-content-between">
                            <h1 className="c-dark-blue fw-semi-bold">{value ?? 0}</h1>
                            <Trend value={value2} />
                        </div>
                    }

                    <div className="d-flex flex-column">
                        {size === 'sm' && !value1 && <h6 className="c-dark-blue fw-semi-bold mt-1">{value ?? 0}</h6>}
                        {size === 'sm' && value1 &&
                            <>
                                <h6 className="c-dark-blue fw-semi-bold mb-1">{value}<br /></h6>
                                <h6 className="c-dark-blue fw-semi-bold">{value1}</h6>
                            </>
                        }

                    </div>
                </>
            }

            {
                isLoading &&
                <div className="placeholder-glow">
                    <div className="d-flex align-items-center mb-2">
                        <span className="c-grey-200 w-25 border-radius placeholder"></span>
                        <img
                            alt="info-circle"
                            className="px-1 invisible"
                            src={`${process.env.PUBLIC_URL}/images/icons/info-circle.svg`} />
                    </div>

                    {size === 'lg' &&
                        <div className="d-flex align-items-center justify-content-between">
                            <h1 className="c-grey-200 w-50 border-radius fw-semi-bold placeholder">&nbsp;</h1>
                            <div className="c-grey-200 fw-semi-bold px-3 border-radius placeholder"></div>
                        </div>
                    }

                    {size === 'sm' &&
                        <div className="d-flex flex-column">
                            {!value1 && <h6 className="c-grey-200 w-50 fw-semi-bold border-radius placeholder">&nbsp;</h6>}
                            {value1 &&
                                <>
                                    <h6 className="c-grey-200 w-50 fw-semi-bold border-radius placeholder mb-1">&nbsp;</h6>
                                    <h6 className="c-grey-200 w-50 fw-semi-bold border-radius placeholder">&nbsp;</h6>
                                </>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default Tile;