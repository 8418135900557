import dateTo12Hour from "./dateTo12Hour";
import { CHART_INTERVAL_6_HOURS, CHART_INTERVAL_DAY } from "../constants/constants";

const getTooltipDateFormat = (point, interval, yearTransitions) => {
    if (!point) return;

    let result = [];
    if (interval < CHART_INTERVAL_6_HOURS)
        result.hours = point?.intervalEnd === point?.intervalStart
            ? dateTo12Hour(point?.intervalStart)
            : dateTo12Hour(point?.intervalStart) + " - " + dateTo12Hour(point?.intervalEnd);
    
    if (interval > CHART_INTERVAL_6_HOURS && interval < CHART_INTERVAL_DAY)
        result.from = yearTransitions.length > 0 
            ? ['day_month_year',  point?.intervalStart] 
            : ['day_month', point?.intervalStart];
    
    if (interval > CHART_INTERVAL_DAY)
        if (point?.intervalEnd === point?.intervalStart)
            result.from = ['day_month', point?.intervalEnd];
        else if(yearTransitions.length > 0 ) {
            result.from = ['day_month_year', point?.intervalStart];
            result.to = ['day_month_year', point?.intervalEnd];
        } else {
            result.from = ['day_month', point?.intervalStart];
            result.to = ['day_month', point?.intervalEnd];
        }
    return result;
}
    
export default getTooltipDateFormat;