import { Outlet, Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import Footer from "./Footer"
import AuthHandler from "../services/common/auth-handler";
import LoadingPage from "../components/organisms/LoadingPage";

const authHandler = AuthHandler();
const AuthAssetRoute = () => {
    const location = useLocation();
    authHandler.retrieveAsset();
    return (
        authHandler.hasAssetAccess()
            ? <>
                <Outlet />
                <Footer />
            </>
            : (!authHandler.isAuthLoading() ?
                <Navigate
                    to={{
                        pathname: '/secure',
                        state: { redirectUrl: location.pathname }
                    }}
                />
                : <LoadingPage />
            )
    )
};

export default AuthAssetRoute;