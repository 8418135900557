import { Colors } from "./colors";

const getAssetTypeColor = (assetType) => {
    switch (assetType?.toLowerCase()) {
        case 'image': return Colors.darkBlue;
        case 'document': return Colors.blue;
        case 'video': return Colors.lightBlue;
        case 'audio': return Colors.red;
        case 'presentation': return Colors.orange;
        case 'creative': return Colors.lightOrange;
        default: return Colors.green;;
    }
}

export default getAssetTypeColor;