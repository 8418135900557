import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DateRangePicker, CustomProvider } from "rsuite";

import Tooltip from "../atoms/Tooltip";

import {
    USER_LANGUAGE_KEY,
    ES_LANGUAGE_KEY,
    FR_LANGUAGE_KEY,
    MAX_MOBILE_SCREEN_WIDTH,
    CALENDAR_OPTIONS,
    CUSTOM_TIME_RANGE_VALUE
} from "../../helpers/constants/constants";

import frFR from "rsuite/locales/fr_FR";
import enGB from "rsuite/locales/en_GB";
import esES from "rsuite/locales/es_ES";

const DateSelectDropdown = ({
    minDate,
    setEndRange,
    setStartRange,
    header,
    description,
    children,
    selectedOption,
    setSelectedOption,
    from,
    to }) => {
    const { t } = useTranslation('common');

    const [maxDate, setMaxDate] = useState();
    const [width, setWidth] = useState(window.innerWidth);
    const [localeKey, setLocaleKey] = useState(enGB);
    const [availabilityMessage, setAvailabilityMessage] = useState([]);
    const [dateRange, setDateRange] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        setMaxDate(today());
        const language = window.localStorage.getItem(USER_LANGUAGE_KEY);
        if (language === FR_LANGUAGE_KEY) setLocaleKey(frFR);
        else if (language === ES_LANGUAGE_KEY) setLocaleKey(esES);
        else setLocaleKey(enGB);

        if (!from || !to) setDateRange([]);
        else setDateRange([from, to]);

        if (width < MAX_MOBILE_SCREEN_WIDTH)
            setAvailabilityMessage([{
                label: t("availability_message_1"),
                value: [new Date(), new Date()]
            }, {
                label: t("availability_message_2"),
                value: [new Date(), new Date()]
            }]);
        else
            setAvailabilityMessage([{
                label: t("availability_message"),
                value: [new Date(), new Date()]
            }]);

        document.addEventListener('click', handleOutsideClick);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            document.removeEventListener('click', handleOutsideClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsOpen(false);
        if (!selectedOption) return;

        let startDay;
        let endDay;
        if (!from || !to || selectedOption.value !== CUSTOM_TIME_RANGE_VALUE) {
            startDay = today();
            endDay = today();
            startDay.setDate(startDay.getDate() - (selectedOption?.value + 1));
            endDay.setDate(endDay.getDate());
        } else {
            startDay = from;
            endDay = to;
        }
      
        setStartRange(startDay);
        setEndRange(endDay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    useEffect(() => {
        if (!from || !to) return setDateRange([]);
        setDateRange([from, to])
    }, [from, to]);

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target))
            setIsOpen(false);
    }

    const today = () => {
        const timeNow = new Date();
        timeNow.setHours(23, 59, 59, 59);
        return timeNow;
    }

    const adjustDate = () => {
        const adjustedDate = today();
        if (width > MAX_MOBILE_SCREEN_WIDTH)
            adjustedDate.setMonth(adjustedDate.getMonth() - 1);
        return adjustedDate;
    };

    const onDateSelect = (value) => {
        setStartRange(value[0]);
        setEndRange(value[1]);
        setDateRange(value);
    }

    return (
        <div className="row d-flex align-items-baseline">
            <div className="col-12 col-md-7 col-lg-9">
                <div className="d-flex justify-content-start">
                    <h6 className="fw-semi-bold text-truncate no-line-height py-2">{header}</h6>
                    {description &&
                        <Tooltip
                            anchor={`tooltip-anchor-date-range`}
                            description={description} />
                    }
                    {
                        children
                    }
                </div>
            </div>
            <div className="col-12 col-md-5 col-lg-3">
                <div style={{minHeight: 36}}
                    className={`d-flex align-items-center justify-content-md-end ${selectedOption?.value === CUSTOM_TIME_RANGE_VALUE ? "justify-content-between" : "justify-content-start"}`}>
                    {selectedOption &&
                        <>
                            <div className={`custom-dropdown ${isOpen ? 'open' : ''}`} ref={dropdownRef}>
                                <div className="dropdown-toggle c-black-100 bc-grey-200 pe-2" onClick={() => setIsOpen(!isOpen)}>
                                    {t(selectedOption?.label)}
                                    <img
                                        alt="Arrow down"
                                        src={`${process.env.PUBLIC_URL}/images/icons/arrow-down.svg`} />
                                </div>
                                <ul className={`dropdown-menu p-2 ${selectedOption?.value === CUSTOM_TIME_RANGE_VALUE && width <= MAX_MOBILE_SCREEN_WIDTH && "ms-0"}`}>
                                    {CALENDAR_OPTIONS?.map((option) => (
                                        <li
                                            key={option.value}
                                            className={`p-2 dropdown-item ${selectedOption?.value === option.value ? 'active' : ''}`}
                                            onClick={() => setSelectedOption(option)}>
                                            <div className="row g-0">
                                                <div className="col-9">
                                                    {t(option.label)}
                                                </div>
                                                <div className="col-3 text-end">
                                                    {selectedOption?.value === option.value &&
                                                        <img alt="selected" src={`${process.env.PUBLIC_URL}/images/icons/check.svg`} />
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {selectedOption.value === CUSTOM_TIME_RANGE_VALUE &&
                                <div className="field text-end">
                                    <CustomProvider locale={localeKey}>
                                        <DateRangePicker showOneCalendar={width <= MAX_MOBILE_SCREEN_WIDTH}
                                            defaultCalendarValue={[adjustDate(), maxDate]}
                                            placement="autoVerticalEnd"
                                            ranges={availabilityMessage}
                                            shouldDisableDate={(dateParam) => { return dateParam <= minDate || dateParam >= maxDate; }}
                                            onOk={value => onDateSelect(value)}
                                            placeholder={t("calendar_placeholder")}
                                            format={t("date:date_format")}
                                            value={dateRange}
                                            onClean={() => setDateRange([])}
                                        />
                                    </CustomProvider>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
export default DateSelectDropdown;
