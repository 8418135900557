// Local storage key constants
export const USER_LANGUAGE_KEY = 'lang';
export const ES_LANGUAGE_KEY = 'es';
export const FR_LANGUAGE_KEY = 'fr';
export const EN_LANGUAGE_KEY = 'en';
export const TENANT_ID_KEY = 'tenant-id';
export const ASSET_ID_KEY = 'asset-id';
export const DAM_REPORTING_DATE_KEY = 'dam_date';
export const USER_REPORTING_DATE_KEY = 'user_date';
export const ACCESS_TOKEN_KEY = 'access-token';
export const REDIRECT_TOKEN_KEY = 'redirect-token';
export const LANGUAGE_KEY = 'language';

// Screen width constants
export const MAX_MOBILE_SCREEN_WIDTH = 576;
export const MAX_TABLET_SCREEN_WIDTH = 768;
export const MAX_TABLET_HORIZONTAL_SCREEN_WIDTH = 992;

// File export constants
export const JPEG = '.jpeg';
export const PNG = '.png';

// Table constants
export const ASSETS_TABLE_CAPACITY = 7;
export const USER_TABLE_ITEM_HEIGHT = 46;
export const USER_TABLE_ITEM_HEIGHT_MOBILE = 58;
export const USER_TABLE_EMPTY_LINE_COUNT = 50;

// Sort order constants
export const ASCENDING_SORT_ORDER = 'ASC';
export const DESCENDING_SORT_ORDER = 'DESC';

// Line chart constants
export const CHART_MARGIN_LG = 49;
export const CHART_MARGIN_XL = 62;

export const CHART_MAX = 10000;
export const CHART_MAX_MD = 100000;

export const CHART_INTERVAL_6_HOURS = 0.25;
export const CHART_INTERVAL_DAY = 1;
export const CHART_INTERVAL_MONTHS = 20;
export const CHART_INTERVAL_YEAR = 21;

// Route constants
export const DEFAULT_ROUTE = '/';
export const USER_REPORTING_ROUTE = '/user-reporting';
export const LOGOUT_ROUTE = '/logout';
export const UNAUTHORIZED_ROUTE_PATH = '/secure';
export const SERVER_ERROR_ROUTE_PATH = '/server-error';
export const FORBIDDEN_ROUTE_PATH = '/forbidden';
export const SESSION_EXPIRED_ROUTE_PATH = '/session-expired';
export const ASSET_ROUTE_PATH = '/asset';
export const ASSET_REPORTING_ROUTE_PATH = '/asset-reporting';
export const NOT_FOUND_ROUTE_PATH = '/not-found';

// URL params names
export const FROM = 'from';
export const TO = 'to';
export const OPTION = 'option';
export const ID = 'id';

// Calendar constants
export const CALENDAR_OPTIONS = [
    { label: "yesterday", value: 1, selected: false },
    { label: "last_7_days", value: 6, selected: false },
    { label: "last_30_days", value: 30, selected: true },
    { label: "last_90_days", value: 90, selected: false },
    { label: "custom", value: 0, selected: false }
];
export const CUSTOM_TIME_RANGE_VALUE = CALENDAR_OPTIONS[4].value;

// Event type constants
export const EVENT_LOGIN = 1;
export const EVENT_UPLOAD = 2;
export const EVENT_SHARE = 3;
export const EVENT_PREVIEW = 4;
export const EVENT_DOWNLOAD = 5;
export const EVENT_DOWNLOAD_FROM_SHARE = 6;
export const EVENT_SHARE_BY_EMAIL = 7;

// Error codes
export const BAD_REQUEST_ERROR_CODE = 400;
export const UNAUTHORIZED_ROUTE_ERROR_CODE = 401;
export const FORBIDDEN_ERROR_CODE = 403;
export const NOT_FOUND_ERROR_CODE = 404;
export const SESSION_EXPIRED_ERROR_CODE = 440;
export const SERVER_ERROR_CODE = 500;
