import qs from "qs";
import formatDate from "./formatDate";

const customSerializer = (params) => {
    let newParams = {...params};
    for (let key in newParams) 
        if (newParams[key] instanceof Date) 
            newParams[key] = formatDate(newParams[key]); 
        
    return qs.stringify(newParams);
}

export default customSerializer;