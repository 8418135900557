import Footer from "../../core/Footer";

const LoadingPage = () => {
    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img
                    alt="dcm-logo"
                    role="button"
                    style={{ height: 200 }}
                    src={`${process.env.PUBLIC_URL}/images/animations/dcm-loader.svg`} />
            </div>
            <Footer />
        </>
    );
}

export default LoadingPage;