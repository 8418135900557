import { useTranslation } from "react-i18next";
import Footer from "../../core/Footer";

const ForbiddenPage = () => {
    const { t } = useTranslation('errors');

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img
                    alt="forbidden"
                    src={`${process.env.PUBLIC_URL}/images/errors/forbidden.svg`} />
                <h1 className="c-dark-blue fw-semi-bold text-center mb-2">{t("forbidden")}</h1>
                <div className="text-center w-75">
                    <span className="c-dark-blue">
                        {t("forbidden_content")}
                    </span>
                </div>
            </div>
            <Footer />
        </>);
}

export default ForbiddenPage;