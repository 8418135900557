import calculatePercentage from "../../helpers/formatting/calculatePercentage";
import formatNumber from "../../helpers/formatting/formatNumber";

const Bar = ({ title, color, value, maxValue }) => {

    const getWidth = () =>{
        return value === 0 ? '100%' : calculatePercentage(value, maxValue);
    }

    const getColor = () =>{
        return value === 0 ? '#ECEDEF' : color;
    }

    return (
        <div className="row d-flex align-items-center mb-3 mt-3 pt-1 g-0">
            <div className="col-10 col-lg-3 col-xl-2 order-1 ellipsis  mb-2 c-dark-blue">
                <span>{title}</span>
            </div>
            <div className="col-12 col-lg-8 col-xl-9 order-3 order-lg-2 pe-lg-3 pe-xl-0 ps-0">
                <div style={{ backgroundColor: getColor(), width: getWidth() }}
                    className="bar rounded-5"></div>
            </div>
            <div className="col-2 col-lg-1 order-2 order-lg-3 text-end">
                <span>{formatNumber(value)}</span>
            </div>
        </div>
    );
}

export default Bar;