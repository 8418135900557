import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import UsersService from "../../../services/users-service";
import EventsService from "../../../services/events-service";

import DateSelectDropdown from "../../../components/molecules/DateSelectDropdown";
import UsersTable from "../../../components/organisms/UsersTable";
import UsersTableMobile from "../../../components/organisms/UsersTableMobile";
import { AssetStateContext } from "../../../contexts/AssetStateContextProvider";

import {
    TENANT_ID_KEY,
    USER_REPORTING_DATE_KEY,
    CALENDAR_OPTIONS,
    CUSTOM_TIME_RANGE_VALUE
} from "../../../helpers/constants/constants";

const usersService = UsersService();
const eventsService = EventsService();
const UsersActivity = () => {
    const { t } = useTranslation(['dam', 'common']);

    const [minDate, setMinDate] = useState(new Date());
    const [endRange, setEndRange] = useState();
    const [startRange, setStartRange] = useState();
    const [selectedOption, setSelectedOption] = useState();

    const [usersActivities, setUsersActivities] = useState([]);
    const [isUsersActivitiesLoading, setIsUsersActivitiesLoading] = useState(true);

    const [showInactiveAssets, ,] = useContext(AssetStateContext);

    useEffect(() => {
        usersService.init();
        eventsService.init();

        let prevOptions = window.localStorage.getItem(USER_REPORTING_DATE_KEY);
        if (prevOptions) {
            prevOptions = JSON.parse(prevOptions);
            setEndRange(new Date(prevOptions?.to));
            setStartRange(new Date(prevOptions?.from));
            setSelectedOption(prevOptions?.option);
        } else
            setSelectedOption(CALENDAR_OPTIONS[2]);

        // workaround to get tenant from localstorage until auth is implemented
        let tenant = window.localStorage.getItem(TENANT_ID_KEY);
        if (!tenant) {
            return;
        }

        eventsService
            .getStartDate(tenant)
            .then((response => setMinDate(new Date(response.data))));

        return () => {
            usersService.dispose();
            eventsService.dispose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!endRange || !startRange) return;
        let tenant = window.localStorage.getItem(TENANT_ID_KEY);

        window.localStorage.setItem(USER_REPORTING_DATE_KEY,
            JSON.stringify({
                from: startRange, to: endRange, option: selectedOption
            }));

        setIsUsersActivitiesLoading(true);
        usersService
            .getActivity(tenant, startRange, endRange, showInactiveAssets)
            .then((response) => {
                setUsersActivities(response.data);
            })
            .finally(() => {
                setIsUsersActivitiesLoading(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endRange, startRange, showInactiveAssets]);

    useEffect(() => {
        let prevOptions = window.localStorage.getItem(USER_REPORTING_DATE_KEY);
        if (prevOptions) {
            prevOptions = JSON.parse(prevOptions);

            if (new Date(prevOptions?.from).toDateString() === startRange?.toDateString()
                && new Date(prevOptions?.to).toDateString() === endRange?.toDateString()
                && selectedOption?.value === CUSTOM_TIME_RANGE_VALUE
                && prevOptions?.option.value !== CUSTOM_TIME_RANGE_VALUE) {
                setEndRange();
                setStartRange();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOption]);

    return (
        <>
            <div className="mb-4">
                <DateSelectDropdown
                    minDate={minDate}
                    setEndRange={setEndRange}
                    setStartRange={setStartRange}
                    header={t('users:user_reporting')}
                    description={t('users:user_reporting_tooltip')}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    from={startRange}
                    to={endRange} />
            </div>
            <div className="d-flex flex-column bc-white p-4 border-radius flex-grow-1 box-shadow">
                <div className="d-none d-lg-flex flex-column flex-grow-1 w-100">
                    <UsersTable
                        data={usersActivities}
                        isLoading={isUsersActivitiesLoading} />
                </div>
                <div className="d-flex d-lg-none flex-column flex-grow-1 ">
                    <UsersTableMobile
                        data={usersActivities}
                        isLoading={isUsersActivitiesLoading} />
                </div>
            </div>
        </>);
}

export default UsersActivity;